// ----- START HEADER HAMBURGER OR X MENU BUTTON STYLES ----- //
.logo-breadcrumb-container {
  align-items: center;
  display: flex;
  .menu-button-container {
    align-items: center;
    background-color: #293042 !important;
    border-radius: 50% !important;
    cursor: pointer;
    margin-right: 30px;
    max-height: 35px;
    max-width: 35px;
    min-height: 35px;
    min-width: 35px;
    outline: none;
    div.close-line-top,
    div.close-x-top {
      background-color: #fff;
      border-radius: 1px;
      height: 3px;
      margin-bottom: 3px;
      transform-style: preserve-3d;
      transition: transform 0.2s ease;
      width: 20px;
    }
    div.close-line-top {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }
    div.close-x-top {
      transform: translate3d(0px, 7px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
    }

    div.close-line-middle,
    div.close-x-middle {
      background-color: #fff;
      border-radius: 1px;
      height: 3px;
      margin-bottom: 3px;
      width: 20px;
    }
    div.close-line-middle {
      opacity: 1;
    }
    div.close-x-middle {
      opacity: 0;
    }

    div.close-line-bottom,
    div.close-x-bottom {
      background-color: #fff;
      border-radius: 1px;
      height: 3px;
      transform-style: preserve-3d;
      transition: transform 0.2s ease;
      width: 20px;
    }
    div.close-line-bottom {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }
    div.close-x-bottom {
      transform: translate3d(0px, -5px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
    }
    &:focus {
      box-shadow: none;
    }
  }
  .breadcrumb-header {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3em;
    .header-info {
      color: #293042;
      margin-left: 10px;
      height: 1rem !important;
      width: 1rem !important;
    }
  }
}
.popover-container {
  border-radius: 6px !important;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.popover-outer-container {
  :global(.arrow::before) {
    border-width: 0px !important;
  }
  > div {
    border: none !important;
  }
}
// ----- END HEADER HAMBURGER OR X MENU BUTTON STYLES ----- //

// ----- HEADER VIDEO GUIDE BUTTON STYLES ----- //
.video-guide-btn {
  color: #000 !important;
  background-color: #bcc2d5 !important;
  border: #bcc2d5 !important;
  transition: filter 0.3s !important;
  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    border: transparent !important;
    filter: brightness(0.8);
  }
}
// ----- END HEADER VIDEO GUIDE BUTTON STYLES ----- //

// ----- START ACCOUNT DROPDOWN STYLES ----- //
.account-button-dropdown {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  margin-left: 5px;
  img {
    border-radius: 2rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    width: 2.5rem;
  }
  .notification-count {
    background: #5753ab;
    border-radius: 50%;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    color: #fff;
    display: block;
    font-size: 0.675rem;
    height: 18px;
    line-height: 1.1rem;
    padding: 1px;
    position: absolute;
    right: 3px;
    text-align: center;
    top: 0;
    transition: top 0.1s ease-out;
    width: 18px;
  }
  &:focus,
  &:active {
    background-color: #ccd2e2;
    box-shadow: none !important;
  }
}
.account-dropdown-menu {
  &:global(.dropdown-menu) {
    background-clip: padding-box !important;
    background-color: #fff !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.2rem !important;
    color: #6c757d !important;
    font-size: 0.825rem !important;
    list-style: none !important;
    margin: 0 !important;
    padding: 0.5rem 0 !important;
    position: absolute !important;
    text-align: left !important;
    top: 110% !important;
    z-index: 1000 !important;
  }
  &.notification {
    &:global(.dropdown-menu) {
      border: none !important;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.2);
      min-width: 30rem !important;
      padding: 0px !important;
    }
    .list-item-notification {
      background-color: #fff;
      border: none;
      border-radius: 4px;
      color: #5753ab;
      cursor: pointer;
      padding-left: 4rem;
      &.new {
        background-color: #f2f2ff;
        color: #293042;
        &:hover,
        &:focus {
          background-color: #e4e3f9;
        }
        &:active {
          background-color: #5753ab;
          color: #fff;
          small {
            color: #fff !important;
          }
        }
      }
      &.earlier {
        &:hover,
        &:focus {
          background-color: #f2f2ff;
        }
        &:active {
          background-color: #5753ab;
          color: #fff;
          small {
            color: #fff !important;
          }
        }
      }
      .read-indicator {
        height: 8px;
        width: 8px;
        background-color: #5753ab;
        border-radius: 50%;
        position: absolute;
        right: 20px;
        top: 50%;
        z-index: 1;
      }
    }
    .list-item-type {
      left: 15px;
      position: absolute;
      top: 40%;
    }
    h3 {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      font-size: 0.9rem;
      margin-bottom: 0rem !important;
      padding: 0.7rem 1rem;
      text-transform: uppercase;
    }
    h4 {
      font-size: 0.8rem;
      font-weight: 600;
      margin-bottom: 0rem !important;
      padding: 0.8rem 1rem;
    }
    h5 {
      font-size: 0.9rem;
      margin-bottom: 0rem !important;
      text-transform: uppercase;
    }
    p {
      font-size: 0.7rem;
      font-weight: 300;
      width: 270px;
    }
  }
  .account-dropdown-menu-item {
    &:global(.dropdown-item) {
      background-color: transparent;
      border: 0;
      clear: both;
      color: #495057;
      cursor: pointer;
      display: block;
      font-weight: 400;
      padding: 0.35rem 1.5rem;
      text-align: inherit;
      white-space: nowrap;
      width: 100%;
      &:active {
        background-color: #2a3142;
        color: white;
      }
    }
  }
  ul {
    list-style-type: none;
    padding: 0px 3px;
    margin-bottom: 0px;
  }
  li {
    &#last-item {
      margin-bottom: 3px;
    }
  }
  .no-unread-notifcations-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    img {
      margin-bottom: 20px;
      max-width: 210px;
    }
    p {
      font-size: 0.9rem;
      margin-bottom: 0;
      text-align: center;
    }
  }
}
// ----- END ACCOUNT DROPDOWN STYLES ----- //

// ----- START FEEDBACK & CREATE NEW USER MODAL CONTAINER STYLES ----- //
// These styles are not to be confused with the actual <FeedbackForm/> or <NewUserCreation/> components //
.navbar-modal {
  :global(.modal-content) {
    border: none;
    overflow: hidden;
  }
  .form-header-wrapper {
    background-color: #2f56a6;
    color: white;
    display: flex;
    font-size: 1.3rem;
    justify-content: space-between;
    padding: 12px;
    text-align: center;
  }
}
// ----- END FEEDBACK & CREATE NEW USER MODAL CONTAINER STYLES ----- //

// ----- START MEDIA QUERIES ----- //
@media print {
  .navbar-container,
  .notification-banner {
    position: initial;
  }
}

@media screen and (max-width: 490px) {
  .breadcrumb-header {
    font-size: 15px;
    max-width: 140px;
  }
}
// ----- END MEDIA QUERIES ----- //
