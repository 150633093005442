.user-profile-container {
  display: flex;
  flex-direction: row;
  height: calc(100% - 54px);

  &.my-profile {
    display: block !important;
  }

  // CentralStaff.js
  .user-profile-main {
    background-color: #3d4761;
    border-radius: 8px;
    height: calc(100vh - 105px);
    margin: 20px;
    min-width: 300px;
    overflow: auto;
    position: sticky;
    top: 20px;
    width: 35%;
    > div {
      background-color: #3e4761;
    }
    .user-search-wrapper {
      background-color: #626b84;
      border-radius: 4px;
      box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.29);
      position: sticky;
      top: 0;

      .user-search-input {
        background-color: #626b84;
        border: none;
        box-shadow: inset -6px 0px 9px 0px rgba(0, 0, 0, 0.29);
        color: #fff;
        height: 40px;
        max-width: 65%;
        &:focus {
          outline: 1px solid #3e4761;
        }
        &.select {
          &:global(.form-select) {
            color: #fff;
            cursor: pointer;
            font-size: 0.875rem;
            text-overflow: ellipsis;
            opacity: 1;
          }
        }
        &:global(.form-control::placeholder) {
          color: white;
          opacity: 1;
        }
      }
      .directory-toggle-btn {
        :global(.btn-check + label) {
          border-color: #3e4761;
          color: #fff;
        }
        :global(.btn-check:checked + .btn) {
          background-color: #3e4761;
        }
      }
    }

    .user-table {
      top: 32px;
      td {
        height: 40px;
        padding: 0px;
        text-align: left;
      }
      .user-row {
        a {
          color: #000;
        }
        .user-name-email {
          font-weight: 300;
          width: 80%;
          button {
            background-color: transparent;
            border: none;
            color: #fff;
            cursor: pointer;
            font-weight: 500;
            text-align: left;
            width: 100%;
          }
        }

        .user-view {
          padding: 4px 0;
          text-align: center;
          width: 15%;

          .user-table-header {
            color: #ffffff;
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 1rem;
            padding-left: 1.2rem;
            text-align: left;
          }

          button {
            background-color: transparent;
            border: none;
            color: #fff;
            width: 100%;

            svg {
              cursor: pointer;
            }
          }

          img {
            border: 2px solid #616c84;
            border-radius: 3rem;
            height: 50px;
            width: 50px;
          }
        }
      }

      tr:nth-child(even) {
        background: #616b84;
      }
    }

    .search-switch {
      align-items: center;
      color: white;
      display: flex;
      font-size: 0.8rem;
      justify-content: center;
      margin-top: 6px;

      span {
        &.label[title] {
          display: inline-block;
          font-size: 0.85rem;
          margin-right: 10px;

          &::before {
            content: attr(title);
            display: block;
            font-weight: 600;
            height: 0;
            overflow: hidden;
            visibility: hidden;
          }

          &.selected {
            font-weight: 600;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .user-profile-info-panel {
    background-attachment: scroll;
    background-image: url('../../../public/img/central-staff-background.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: calc(100vh - 86px);
    overflow: auto;
    padding: 20px 20px 20px 0;
    width: 65%;

    .image-and-text-placeholder {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 100%;

      img {
        height: 180px;
        width: 180px;
      }

      h1 {
        font-size: 1.5rem;
        margin-top: 20px;
      }
    }

    .staff-directory-cover-image {
      height: 100px;
      object-fit: cover;
      width: 100%;

      background-image: linear-gradient(
        180deg,
        #000,
        #7c7c7c 0%,
        hsla(0, 0%, 100%, 0)
      );
    }

    .staff-directory-details {
      td:first-of-type {
        padding: 0px 15px 0px 0px;
      }
    }

    .staff-associations {
      border: 0px;
    }
  }

  //New for warning message

  .custom-alert {
    background-color: #8802fd;
    border: 1px solid #000000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    left: 50%;
    padding: 20px;
    position: fixed;
    top: 50%;
    transform: translate(70%, 70%);
    width: 300px;
  }

  .custom-alert h2 {
    margin-top: 0;
  }

  .custom-alert button {
    margin-top: 10px;
  }

  .edit-profile-button {
    background-color: #fff;
    border: 0.5px solid #dedede;
    border-radius: 4px;
    color: #505050;
    font-size: 0.8rem;
    margin-top: 6px;
    padding: 4px 8px;
    position: absolute;
    right: 5px;
  }

  .user-profile-left-details {
    td:first-of-type {
      padding: 0px 15px 0px 0px;
    }

    .change-password-link {
      color: #5770b1;
      font-size: 0.8rem;
      font-weight: 200;
      padding: 0;
      text-decoration: none;
    }
  }

  // NotificationsHistory.js
  .reports-past-due-header {
    font-size: 2rem;
    font-weight: 400;
    margin: 0px !important;
    padding: 10px;
  }

  .user-profile-notifications {
    background-color: #fff;
    border-radius: 8px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
    overflow-y: scroll;
    padding: 20px 40px;
    width: 95%;

    .notification-history-item {
      cursor: pointer;
      display: flex;
      padding: 10px;

      &:hover {
        background-color: #e4eaf6;
        border-radius: 8px;
      }

      .notification-index {
        color: #666666;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px;
        margin-right: 30px;
      }

      .notification-content {
        max-width: 75%;

        .notification-type {
          font-size: 18px;
          font-weight: 500;
          margin: 0;
          padding: 0;
          text-transform: uppercase;
          width: 75%;
        }

        .notification-title {
          color: #666666;
          font-size: 15px;
          font-weight: 500;
          margin: 0;
          padding: 0;
          text-transform: capitalize;
          white-space: normal !important;
          width: 245px;
          word-wrap: break-word !important;
        }
      }

      .notification-key {
        background-color: #dc3545;
        border-radius: 3px;
        color: white;
        font-size: 0.8rem;
        height: fit-content;
        margin-left: auto;
        margin-right: 0px;
        padding: 3px 8px;
        right: 0;
      }
    }

    .notification-separator {
      border-color: #f1f1f1;
      margin: 5px;
      width: 100%;
    }
  }

  h2 {
    font-size: 1.8rem;
  }
}

.accordian-style {
  
  button{
    font-size: 0.75rem;
    font-weight: 600;
    background-color: transparent;
    padding: 0px 8px !important; 

    &:disabled{
      color: #cecece;
      &::after {
        display: none;
      }
    }
    
    &::after {
      margin-left: 5px;
    }
  }
}

.edit-profile-button {
  background-color: #fff;
  border: 0px;
  border-radius: 4px;
  color: #4e46d2;
  font-size: 0.9rem;
  padding: 3px 8px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.user-guide-field-icon {
  margin-left: 5px;
}

.user-profile-pref-container {
  table {
    width: 100%;
    h4 {
      color: #999999;
    }
    td {
      div:last-of-type {
        border-bottom: 0 !important;
        margin-bottom: 0 !important;
      }
    }
    ul {
      margin-bottom: 0;
      li {
        margin-bottom: 0.7rem;
        span {
          font-size: 0.9rem;
        }
        div {
          align-items: center;
        }
      }
    }
    .preference-border {
      border: 1px solid #eeeeee;
      border-radius: 5px;
    }
    .label-max-width {
      max-width: 340px;
      .sub-label {
        font-size: 12px;
      }
    }
    .user-email-pref-toggle .form-check-input {
      height: 1.25rem; /* Always 20px */
      width: 2.375rem; /* Always 38px */
    }

    .user-email-pref-toggle:global(.form-check-input):checked {
      background-color: #293043 !important;
      border-color: #293043 !important;
    }
  }
}

// APPEARS ACROSS MULTIPLE FILES
.users-name {
  color: #fff;
  font-size: 1.8rem;
  margin-bottom: 13px;
  margin-left: 20px;
  margin-top: -87px;
}

.profile-container {
  .user-profile-picture {
    background-position: center;
    background-size: cover;
    border: 5px #ffffff solid;
    border-radius: 4rem;
    cursor: pointer;
    height: 100px;
    margin-left: 20px;
    overflow: hidden;
    position: relative;
    width: 100px;

    &::before {
      background: rgba(0, 0, 0, 0.5);
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 0.3s ease;
    }

    &:hover::before {
      opacity: 1;
    }

    .camera-icon {
      color: #fff;
      display: none;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover .camera-icon {
      display: block;
    }
  }
}

.char-remaining {
  font-size: 0.8rem;
}

.staff-directory-profile {
  border: 5px #ffffff solid;
  border-radius: 4rem;
  height: 100px;
  margin-left: 20px;
  width: 100px;
}

.edit-user-modal-wrapper {
  max-width: 740px !important;

  :global(.modal-content) {
    overflow: visible;
  }

  .edit-user-modal-body {
    padding: 0px !important;

    .popup-close-icon {
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      font-size: 1.8rem;
      left: 690px;
      margin-right: 20px;
      position: sticky;
      top: 1rem;
      width: 25px !important;
      z-index: 1;

      &:hover {
        background-color: #dcdcdc;
        color: #000;
        transition: 0.2s ease;
      }
    }
  }
}

.association-label-top {
  padding-right: 5px;
  vertical-align: top;
}

:global(.modal-dialog) {
  &.usedr-profile-image-modal {
    max-width: 650px;

    :global(.modal-content) {
      background-color: #293042;
      color: white;
    }

    :global(.modal-body) {
      min-height: 400px;
    }

    :global(.modal-footer) {
      background-color: #3c4459;

      .picture-capture-button-footer {
        display: flex;
        justify-content: center;
        width: 100%;

        .button-capture-container {
          position: relative;

          .picture-capture-button {
            background-color: #f1f5ff;
            border: none;
            border-radius: 50%;
            color: #293042;
            cursor: pointer;
            font-size: 18px;
            height: 80px;
            outline: none;
            position: relative;
            transition: background-color 0.3s ease;
            width: 80px;

            &::before {
              border: 4px solid #293042;
              border-radius: 50%;
              content: '';
              height: 65px;
              left: 50%;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 65px;

              // Adds a white circle around the icon
            }

            &:hover {
              background-color: #dbe5ff;
            }

            &:active {
              background-color: #acb4ca;
            }
          }
        }
      }
    }
  }
}

.cancel-webcam-capture {
  color: white !important;
  position: absolute;
  top: 60%;
  transform: translate(-110%, -60%);
  width: 101px;

  &:hover {
    background-color: #9292923b !important;
    border-radius: 2px;
    box-shadow: none !important;
    color: #252525;
    outline: none;
  }
}

.profile-image-action-btns {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10%;
  bottom: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  left: 10px;
  position: absolute;
  width: 30px;

  &.bottom-right {
    border-radius: 2rem;
    left: auto;
    right: 10px;
  }
}

.fullscreen-btn {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10%;
  color: white;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  position: absolute;
  right: 35px;
  top: 13px;
  width: 30px;
}

.fullscreen-image-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  .fullscreen-image {
    max-height: 90%;
    max-width: 90%;
  }

  .close-fullscreen-btn {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
  }
}

// Skeleton laoder for webcam
@keyframes skeleton-animation {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: -100% 50%;
  }
}

.skeleton-loader {
  animation: skeleton-animation 1.5s infinite;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  display: inline-block;
}

// Applies styles to the image crop selector box (circle)
:global(.ReactCrop) {
  border-radius: 0.5rem !important;
}

:global(.ReactCrop__drag-handle) {
  display: none !important;
}

:global(.ReactCrop__crop-selection) {
  border: 3px solid white !important;
}
