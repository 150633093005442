// ----- START myTasks/ReviewPage.js STYLES ----- //
.back-button{
    background-color: #fff;
    border: none;
    border-radius: 0px 4px 4px 0px;
    color: #4540a2;
    padding: 7px 14px;
    position: fixed;
    text-decoration: none;
    top: 82px;
    z-index: 1;
    &:hover{
        background-color: #e8e8e8 !important;
        text-decoration: none;
    }
}
.page-review-hero-section{
    background-color: #4540a2;
    color: #fff;
    padding: 5rem 2rem 256px 2rem;
    text-align: center;
    >span{
        font-weight: 600;
    }
}
.review-clex-wrapper{
    background-color: #F8FAFC;
    border-radius: 10px;
    box-shadow: 0px 20px 40px 0px rgba(0,0,0,0.25);
    display: flex;
    height: 60vh;
    margin: -225px auto 30px;
    max-width: 1200px;
    padding: 20px;
    width: 90%;
    .page-preview-container{
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.10);
        margin-left: 10px;
        max-width: 900px;
        overflow: auto;
        padding: 20px;
        width: 100%;
    }
}
.footer {
    text-align: center;
    padding-bottom: 15px;
}
@media screen and (max-width: 770px){
    .review-clex-wrapper{
        overflow: auto;
        .page-preview-container{
            display: none;
        }
    }
}
// ----- END /myTasks/ReviewPage.js STYLES ----- //

// ----- START /myTasks/ReviewList.js STYLES ----- //
table.review-table {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    tr {
        border: 1px solid #e4eaf6;
    }   
    th {
        background-color: #586eb2;
        border: 0px;
        cursor: default !important;
        font-weight: 400;
        height: 40px;
        text-align: center;
    }
    tbody tr:hover {
        cursor: pointer;
        background-color: #ececff;
    }
    td {
        border: none;
        border-bottom: 1px solid rgba(189, 189, 189, 0.38);
        border-top: 1px solid rgba(189, 189, 189, 0.38);
        font-size: .9rem;
        height: 60px;
        padding: 10px;
        text-align: center;
        width: 90px;
    }
    thead tr th:first-child,
    tbody tr td:first-child {
        width: 4em;
        min-width: 4em;
        max-width: 4em;
    }
}
// ----- END /myTasks/ReviewList.js STYLES ----- //

// ----- START myTasks/ReviewPage.js, myTasks/ReviewList.js SHARED STYLES----- //
.item {
    background: #586eb2;
    border-radius: 20px;
    color: #fff;
    display: inline-block;
    font-size: .8rem;
    line-height: 1.3em;
    margin: 1px;
    padding: 4px 12px;
    width: fit-content;
    &.added {
        background-color:#aaffaa !important;
        color: #006400;
    }
    &.removed {
        background-color:#ffaaaa !important;
        color: #8B0000;
    }
}
// ----- END myTasks/ReviewPage.js, myTasks/ReviewList.js SHARED STYLES----- //