@import '~bootstrap/scss/bootstrap.scss';

.welcome-calendar {
  align-items: center;
  background-attachment: scroll;
  background-image: url('../../../public/img/welcome-to-your-calendar.jpg');
  background-position: 50% 70%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0px !important;
  border-radius: 12px;
  height: 210px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  button:global(.close) {
    color: white;
    opacity: 1;
  }
}
:global(.offcanvas) {
  width: 80% !important;
}
:global(.offcanvas-container.open) {
  .display-mode-button {
    right: 80%;
    padding: 4px 2px;
  }
}

@media screen and (max-width: 768px) {
  :global(.offcanvas) {
    width: 90% !important;
    transition: 0.25s ease-in-out;
  }
  :global(.offcanvas-container.open) {
    .display-mode-button {
      right: 90% !important;
      transition: 0.25s ease-in-out;
    }
  }
}
:global(.offcanvas-body) {
  padding: 0 !important;
}

:global(.offcanvas-container) {
  .display-mode-button {
    background-color: #5770b1 !important;
    border: 1px #5770b1 solid !important;
    border-radius: 4px 4px 0px 0px;
    color: #fff !important;
    min-width: 175px;
    padding-bottom: 30px;
    padding-top: 5px;
    position: absolute;
    right: -10px;
    top: 50%;
    transition: right 0.25s ease-in-out;
    transform: rotate(-90deg) translateY(-17px) translateX(50%);
    transform-origin: right;
    z-index: 1050;
    &:hover {
      background-color: #3a4b75 !important;
      border: 1px #3a4b75 solid !important;
    }
    .arrow {
      transform: rotate(90deg);
      margin-right: 10px;
    }
    &.enlarge:hover {
      transform: rotate(-90deg) translateY(-30px) translateX(calc(50%));
      transition: all 0.1s ease-in;
    }
  }
}

// .create-report-button {
//     background: #5770B1 !important;
//     border: 0px !important;
//     border-radius: 50%;
//     bottom: 40px;
//     box-shadow: 10px 0px 20px 0px rgba(0,0,0,0.2);
//     color: white;
//     height: 75px;
//     padding: 0px 12px;
//     position: absolute;
//     right: 20px;
//     transition: all .3s;
//     width: 75px;
//     -webkit-transition: all .3s;
//     z-index: 1;
//     &:hover { background-color:#3e5182 !important; }
//     &:active{ background-color:#324169 !important; }
//     &.non-leg-staff{ visibility: hidden; }
// }
