.body-container {
	h2 {
		font-size: 2.5rem;
		margin-bottom: 20px;
	}

	h3 {
		font-size: 1.5rem;
		margin-bottom: 20px;
	}

	h4 { font-size: 1.1rem; }

	h6 { font-weight: 600; }

	li { margin: 10px 0; }

	p {
		font-size: 0.9rem;
		line-height: 1.6rem;
	}

	ol{ font-size: 0.9rem; }

	section{ 
		scroll-margin-top: 2.2rem; 
		margin-bottom: 4rem;
	}

	.user-manual-screen-captures {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border: 4px solid #B9B9B9;
		border-radius: 8px;
		cursor: zoom-in;
		display: block;
		height: 390px;
		margin-bottom: 25px;
		max-width: 750px;
		width: 100%;s
		&.account-dropdown { max-width: 200px; }
	}

	.user-manual-sidebar-container {
		background-color: #e4eaf6;
		height: calc(100vh - 50px);
		overflow: auto;
		padding: 20px;
		position: fixed;
		width: 260px;
		z-index: 999;
		ul li {
			a{
				color: #495057;
				font-weight: 600;
				position: relative;
				text-decoration: none;
				&.pointer{
					cursor: pointer;
					&.indicator::after{
						content: url(black-chevron-down.svg);
						flex-shrink: 0;
						height: 15px;
						position: absolute;
						right: 13px;
						top: 22%;
						transition: transform .2s ease-in-out;
						width: 15px;
					}
				}
			}
			&:hover {
				background-color: #cbd2e0;
				border-radius: 4px;
			}
		}

		.user-manual-sub-nav {
			.nested-nav-item{
				padding: 0rem;
				&:hover{
					background-color: transparent;
					a{ color: #495057; }
				}
				>a{
					border-radius: 4px;
					margin-bottom: 10px;
					margin-right: 15px;
					padding: 4px 8px;
					&:hover {
						background-color: #354668;
						color: #ffff;
						&.indicator::after{
							content: url(white-chevron-down.svg);
						}
						&:global(:not(.collapsed)::after){
							content: url(white-chevron-up.svg);
						}
					}
					&:global(:not(.collapsed)::after){
						content: url(black-chevron-up.svg);
					}
				}
				li{
					&:hover {
						background-color: #354668;
						a{ color: #ffff; }
					}
				}
			}
			ul {
				line-height: 1.2rem;
				list-style: none;
				margin-left: -23px;
				li {
					border-radius: 4px;
					margin-bottom: 10px;
					margin-right: 15px;
					padding: 4px 8px;
					&:hover {
						background-color: #354668;
						a{ color: #ffff; }
					}
					a{
						display: block;
						font-weight: 300;
					}
				}
			}
		}
		li { margin: 1px 0; }
	}
	.user-manual-content-container {
		height: calc(100vh - 54px);
		margin-left: 260px;
		max-width: 980px;
		overflow: auto;
		padding: 20px;
		position: relative;
		.user-manual-iwomm-logo {
			margin-bottom: 1.4rem;
			max-width: 300px;
			width: 100%;
		}
		.column-definitions-container {
			border-radius: 4px;
			margin-bottom: 25px;
			overflow: hidden;
			position: relative;
			transition: ease .3s;
			&:hover{
				&::before{
					content:'';
					box-shadow: inset 0px 0px 10px 3px rgba(0,0,0,0.2);
					display: block;
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}
			}
			ul{
				height: 300px;
				margin-bottom: 0px !important;
				overflow: auto;
				padding-right: 20px;
			}
		}
		.sub-list-container {
			margin-left: 0px !important;
			max-width: 840px !important;
		}
	}
}

@media only screen and (min-width: 768px) {
	.sub-section-heading-column {
		text-align: right;
	}
}