.return-button{
    background-color: #fff;
    border-radius: 4px;
    color: #484848;
    margin-left: 15px;
    padding: 4px 15px;
    text-decoration: none;
    transition: .3s ease;
    &:hover{
        background-color: #7388c1;
        color: #fff;
        text-decoration: none;
    }
}
.no-history-lsr{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: center;
    width: 100vw;
    img{
        margin-bottom: 20px;
        max-width: 300px;
    }
    h3{
        font-size: 1.5rem;
    }
    a{
        text-decoration: none;
    }
}
.lsr-version-review-container{
    background-color: white;
    border-radius: 8px;
    margin: 20px 0 20px 20px;
    max-width: 1000px;
    padding-top: 20px;
    width: 65vw;
    h3{
        background-color: #575D6C;
        color: #fff;
        font-size: .9rem;
        margin-bottom: 0px !important;
        padding: 10px;
        position: sticky;
        text-align:center;
        top: 0;
        width: 100%;
        &.border-right{
            border-right: 1px solid white;
        }
        &.border-left{
            border-left: 1px solid white;
        }
    }
}
.lsr-history-panel{
    border: none !important;
    box-shadow: 0 6px 12px 0 #575d6c61 !important;
    max-width: 333px !important;
    top: 68px !important;
}
:global(.offcanvas.show){
    .history-select-wrapper{
        height: 100%;
        margin: 0;
        width: 100%;
    }
}
.archive{
    align-items: center;
    background-color: #d8deeb;
    border-radius: 4px;
    box-shadow: -1px 2px 20px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    font-size: .9rem;
    justify-content: space-between;
    margin: 20px 16px;
    min-height: 50px;
    padding: 5px 10px;
    transition: .1s ease;
    &.selected{
        background-color: #575D6C;
        box-shadow: -8px 2px 10px 0px rgba(0, 0, 0, 0.1);
        color: #fff;
    }
    &:hover{
        background-color: #7388c1;
        color: #fff;
    }
    .edited-by{
        font-size: .8rem;
        span{
            font-weight: 500;
        }
    }
    .current-version-text{
        font-size: .8rem;
        margin-left: 30px;
        text-transform: uppercase;
    }
    .revert-button{
        background-color: #7875b3;
        border: none;
        border-radius: 4px;
        color: #ffffff;
        outline: none;
        padding: 2px 10px;
        &:hover{
            box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.21);
        }
    }
}
.ls-fields-container{
    display: flex;
    height: calc(100vh - 270px);
    overflow: auto;
    width: 100%;
    .ls-sidebar-fields{
        padding: 12px;
        width: 100%;
        .fieldset-styles{
            background-color: #f0f4fb;
            border-color: #d8e5ff;
            border-radius: 6px;
            border-style: solid;
            border-width: 1px;
            display: block;
            margin-bottom: 20px;
            margin-inline-end: 2px;
            margin-inline-start: 2px;
            min-inline-size: min-content;
            padding-block-end: 0.625em;
            padding-block-start: 0.35em;
            padding-inline-end: 0.75em;
            padding-inline-start: 0.75em;
            &:last-of-type{
                margin-bottom:5px;
            }
            &:hover{
                background-color: #f0f4fb;
            }
            &.duplicate-check{
                border-color: #f1f1f1;
                &:hover{
                    background-color: #f1f1f1;
                }
            }
            .fieldset-legend-styles{
                background-color: #fff;
                border-radius: 4px;
                float: none;
                font-size: 0.8rem;
                font-weight: 300;
                line-height: inherit;
                margin-bottom: 0;
                padding: 0px 4px;
                white-space: normal;
                width: auto;
            }
            .fieldset-content-styles{
                font-size: .8rem;
                font-weight: 500;
                .pre {
                    color: inherit;
                    font-size: inherit;
                    margin: 0px;
                    overflow-y: auto;
                    white-space: pre-wrap;
                    word-break: break-word;
                }
            }
            &.highlight{
                background-color: #fffce0;
                border-color: #eade61;
            }
        }
    }
}
.history-select-wrapper{
    background-color: white;
    border-radius: 8px;
    height: calc(100vh - 94px);
    margin: 20px;
    max-width: 380px;
    overflow: auto;
    width: 35vw;
    z-index: 1;
    h5{
        background-color: white;
        border-bottom: 1px solid #f1f5ff;
        font-size: 1.1rem;
        padding: 10px 0 10px 16px;
        position:sticky;
        top: 0;
        width: 100%;
    }
}
@media screen and (max-width: 924px){
    .lsr-version-review-container{
        margin: 20px;
        width: 100vw;
    }
}