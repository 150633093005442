.tag {
  -webkit-transition-duration: 0.4s; /* Safari */
  background-color: #2f56a6;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2%;
  margin-right: 1%;
  padding: 3px 5px;
  position: relative;
  text-decoration: none;
  transition-duration: 0.4s;
  span {
    vertical-align: middle;
  }
  .delete-icon {
    margin-left: 5px;
  }
}
.tag-title {
  width: 100%;
}
.tag:hover {
  background-color: #23417d;
}

.tag:hover .delete-icon {
  visibility: visible;
}
.tag-form-group {
  position: relative;
}
.input-tag-title {
  font-weight: 500;
  margin-bottom: 1%;
  margin: 0;
  padding: 0;
}
.search-dropdown {
  background-color: white;
  border-top: none;
  border: 1px solid #ccc;
  display: block;
  list-style: none;
  margin: 0;
  max-height: 120px;
  overflow-y: scroll;
  padding: 0;
  width: 100%;
  z-index: 100;
}

.search-dropdown li {
  cursor: pointer;
  padding-left: 2%;
}

.search-dropdown li:hover {
  background-color: #719ece;
  color: white;
}
.input-text-area {
  composes: input-text-area from './CreateNewReportDdl.module.scss';
}
