.delete-modal {
  margin: 12rem auto !important;
}

.file {
  align-items: center;
  background-color: #eaecef;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  padding: 0.5rem 0.3rem;

  &.highlighted {
    background-color: #293042;
    color: white;
    font-weight: 500;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .file-name-wrapper {
    align-items: center;
    display: flex;
  }

  img {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.prompt-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;

  button {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    padding: 0.5rem 1rem;
    transition: background-color 0.2s;

    &.cancel {
      background-color: #e0e0e0;
      color: #333;

      &:hover {
        background-color: #d0d0d0;
      }
    }

    &.delete {
      background-color: #dc3545;
      color: white;

      &:hover {
        background-color: #c82333;
      }
    }
  }
}

.prompt-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  padding: 1.5rem;
  width: 90%;
}

.prompt-message {
  color: #333;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.prompt-overlay {
  align-items: center;
  backdrop-filter: blur(2px);
  background-color: rgba(98, 98, 98, 0.4);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-backdrop-filter: blur(2px);
  z-index: 1030;
}

.trash {
  cursor: pointer;
}

.warning-message {
  color: red;
}
