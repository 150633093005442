.create-new-report-title {
  color: white;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  text-align: left;
}

.input-text-title {
  font-weight: 500;
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
  display: inline-block;
}
.tracker-type-container{
  width: 50%;
}
.tracker-type-select{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.tracker-type-select:checked + .tracker-type-select-label{
  background-color: #495057 !important;
  color: #FFF !important;
}
.tracker-type-select-label{
  border-color: #495057 !important;
  border-radius: 15px !important;
  color:#495057 !important;
  height: 200px;
  margin: 25px 10px !important;
  padding: 40px !important;
  &:active, &:hover{
    background-color: #737c86 !important;
    color: #FFF !important;
  }
  .radio-title{
    font-size: 1.1rem;
  }
}

.file-size-text {
  font-size: 0.65rem;
}

.file-error {
  color: red;
}

.user-guide-field-icon{
  margin-left: 4px;
  color: #838383;
}

.user-guide-field-icon-procs{
  margin-left: 4px;
  color: #000000;
}

.input-text-area {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  width: 100% !important;
}

.input-text-area:focus {
  border-color: #719ece;
  box-shadow: 0 0 10px #719ece;
  outline: none !important;
}

.input-text-area-warning {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  display: block;
  font-size: 1rem;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  width: 100%;
}

.input-text-area-warning:focus {
  border-color: #d05d4e;
  box-shadow: 0 0 10px #d05d4e;
  outline: none !important;
}

.file-padding {
  margin-top: 10px;
  margin-bottom: 0px;
}

.warning-message {
  color: rgb(221, 68, 47);
  font-weight: 400;
}

.mult-selection-notice {
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
}

.report-fields {
  background-color: #fff;
  display: flex;
  font-size: 0.75rem;
  height: 50vh;
  min-width: 350px;
  overflow: auto;
  padding: 15px 15px 0px;
  input,
  select {
    font-size: 12px;
  }
  .report-field-column {
    min-width: 50%;
    .tags-container { width: inherit; }
    > div:last-of-type{ padding-bottom:15px; }
  }
  div.report-field-column:first-child {
    padding-right: 15px;
  }
  div.report-field-column:last-child {
    padding-left: 15px;
  }
  .report-field-column-full {
    width: 100%;
  }
}
.form-container {
  display: flex;
  flex-direction: column;
}
.disclaimer {
  font-size: 12px;
  margin: 10px 0 0 0;
  padding: 0 10px;
  text-align: justify;
}

.tracker-type-page{
  padding: 15px 15px 0px;
  div {
    div {
      display: flex;
      font-size: 1rem;
      input {
        margin: -20px 0px 5px;
      }
      label {
        margin: 0px 5px;
      }
    }
  }
  .radio-description {
    font-size: 0.8rem;
    margin-top: 30px
  }
}

.end_date_question {
  label {
    display: flex;
    input {
      margin: -10px 5px 5px;
    }
  }
}

.radio-buttons {
  input[type="radio"] {
    margin-right: 5px;
  }
  label {
    margin-right: 10px;
  }
}

.custom-text-radio-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  #custom-text-4{
    align-self: baseline;
    margin-top: 2px;
  }
}

.custom-text-radio-button-label {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  text-align: justify;
  width: 290px;
  button {
    padding: 4px 4px
  }
}

.custom-text-upload-container {
  display: flex;
  flex-direction: column;
  width: 500px
}

.modal-header {
  composes: popup-title-container from "./ReportServicePopup.module.scss";

  & > div:first-child {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &.success{
      display: none;
  }
  .popup-close-icon{
    cursor: pointer;
    font-size: 1.8rem;
    height: 25px;
    width: 25px; 
    &:hover{
      background-color: #ffffff3b;
      border-radius: 2px;
    }
    &:active{
        color: #96b3fb;
    }
  }
}

.modal-header-citations {
  background-color: #42293D;
}
.model-header-track-op-req {
  display: flex;
  justify-content: center !important;

  & > span:first-child {
    position: absolute;
    left: 2rem;
  }
}
.modal-footer {
  align-items: center;
  background-color: #f3f3f3;
  display: flex;
  padding: 10px;
  z-index: 1;
  button:first-child {
    float: left;
    margin-right: auto;
  }
  button:nth-child(2) {
    float: right;
    margin-left: auto;
  }
}

.footer-button {
  font-size: 0.75rem !important;
  width: 36% !important;
}

.cancel-button {
  background: #ca4a4a !important;
  composes: footer-button;
}

.back-button {
  float: left;
}

.next-button {
  float: right;
}

.back-button, 
.next-button{
  width: 63px;
  cursor: pointer;
  span {
    font-size: 1rem;
    font-weight: 200;
  }
  &:hover{
    color: #96b3fb;
  }
  &:active{
    color: #829ee4;
  }
}

.progress-bar {
  border-radius: 0 !important;
  height: 0.25rem !important;
}

.alert-danger-banner {
  align-items: center;
  background-color: #ca4a4a !important;
  border-color: #ca4a4a !important;
  border-radius: 0 !important;
  color: white !important;
  display: flex;
  height: 58px;
  justify-content: center;
  margin-bottom: 0px !important;
  p{
    font-size: .8rem;
    margin-bottom: 0rem;
    padding-left: 10px;
  }
  &.success{
    background-color: #8eca4a !important;
    border-color: #8eca4a !important;
    p{
      font-size: .8rem;
      margin-bottom: 0rem;
      padding-left: 10px;
    }
  }
}

.fixed-loading-container {
  background-color: white;
  height: 59vh;
  overflow: hidden;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.file-dropdown {
    margin-bottom: 10px;
}

.popover-container {
  box-shadow: 0px 20px 40px 0px rgba(0,0,0,0.2);
  border-radius: 6px !important;
  padding: 6px;
  div{
    font-size:.7rem;
  }
  &.popover-wide {
    background-color: #FFF;
    width: 600px !important;
  }
}



@media screen and (max-width: 688px) {
  .popover-container.popover-wide {
    width: 400px !important;
  }
}

.popover-reason-body {
  background-color: white;
}

.popover-outer-container{
  :global(.arrow::before){
    border-width: 0px !important;
  }
  > div {
    border: none !important;
  }
}

@media screen and (max-width: 550px) {
  .report-fields {
    flex-direction: column;
    div.report-field-column {
      padding: 0px;
    }
  }
}
