.D1401 {
  height: 8.2%;
  transform: translate(87%, 101.2%);
  width: 12.6%;
}

.D1402 {
  height: 8.3%;
  transform: translate(82%, 101.2%);
  width: 4.7%;
}

.D1403 {
  height: 8.3%;
  transform: translate(76.3%, 101.2%);
  width: 5.5%;
}

.D1404 {
  height: 8.3%;
  transform: translate(70%, 101.2%);
  width: 6%;
}

.D1405 {
  height: 8.25%;
  transform: translate(65.25%, 101.2%);
  width: 4.8%;
}

.D1406 {
  height: 8.25%;
  transform: translate(59.5%, 101.2%);
  width: 5.5%;
}

.D1407 {
  height: 8.25%;
  transform: translate(54%, 101.2%);
  width: 5.2%;
}

.D1408 {
  height: 8.25%;
  transform: translate(43.7%, 101.2%);
  width: 10%;
}

.D1409 {
  height: 8.25%;
  transform: translate(38.25%, 101.2%);
  width: 5.25%;
}

.D1410 {
  height: 8.25%;
  transform: translate(32.7%, 101.2%);
  width: 5.25%;
}
.D1411 {
  height: 8.25%;
  transform: translate(27%, 101.2%);
  width: 5.5%;
}
.D1412 {
  height: 8.25%;
  transform: translate(21.3%, 101.2%);
  width: 5.5%;
}
.D1413 {
  height: 8.25%;
  transform: translate(15.5%, 101.2%);
  width: 5.4%;
}
.D1414 {
  height: 8.25%;
  transform: translate(9.5%, 101.2%);
  width: 5.6%;
}
.D1415 {
  height: 8.25%;
  transform: translate(0.25%, 101.2%);
  width: 9%;
}

.D1416 {
  height: 4%;
  transform: translate(25.7%, 92.9%);
  width: 3%;
}

.D1417 {
  height: 3.8%;
  transform: translate(25.6%, 88.2%);
  width: 3%;
}

.D1418 {
  height: 4%;
  transform: translate(28.8%, 88.2%);
  width: 3%;
}

.D1419 {
  height: 4%;
  transform: translate(29%, 92.9%);
  width: 3%;
}

.D1420 {
  height: 7.6%;
  transform: translate(34.5%, 88.2%);
  width: 3.7%;
}

.D1421 {
  height: 7.6%;
  transform: translate(38.5%, 88.2%);
  width: 3%;
}

.D1422 {
  height: 7.6%;
  transform: translate(44.7%, 88.2%);
  width: 3.9%;
}

.D1423 {
  height: 7.6%;
  transform: translate(48.8%, 88.2%);
  width: 4.2%;
}

.D1424 {
  height: 7.6%;
  transform: translate(56%, 88.2%);
  width: 4.2%;
}

.D1425 {
  height: 7.6%;
  transform: translate(60.2%, 88.2%);
  width: 4.2%;
}

.D1426 {
  height: 7.6%;
  transform: translate(67.7%, 88.2%);
  width: 4.2%;
}

.D1427 {
  height: 7.6%;
  transform: translate(72%, 88.2%);
  width: 4.2%;
}

.D1428 {
  height: 3.2%;
  transform: translate(78.9%, 93.9%);
  width: 4%;
}

.D1429 {
  height: 5.8%;
  transform: translate(78.9%, 88.2%);
  width: 4%;
}

.D1430 {
  height: 5.8%;
  transform: translate(83%, 88.2%);
  width: 4%;
}

.D1431 {
  height: 3.2%;
  transform: translate(83%, 93.9%);
  width: 4%;
}

.D1432 {
  height: 3.8%;
  transform: translate(89.8%, 93.4%);
  width: 8.8%;
}

.D1433A {
  height: 4.8%;
  transform: translate(89.8%, 88.1%);
  width: 8.5%;
}

.D1433B {
  height: 3.8%;
  transform: translate(89.8%, 81.2%);
  width: 8.7%;
}

.D1434 {
  height: 3.8%;
  transform: translate(89.8%, 76.8%);
  width: 8.7%;
}

.D1435A {
  height: 7.8%;
  transform: translate(82%, 77%);
  width: 5.2%;
}

.D1435B {
  height: 5%;
  transform: translate(82%, 71.6%);
  width: 5.2%;
}

.D1436 {
  height: 8.6%;
  transform: translate(70.2%, 76.5%);
  width: 7%;
}

.D1437 {
  height: 8.6%;
  transform: translate(64.5%, 76.5%);
  width: 5.6%;
}

.D1438 {
  height: 7.6%;
  transform: translate(48.8%, 77%);
  width: 15.5%;
}

.D1439 {
  height: 8.6%;
  transform: translate(43.7%, 76.5%);
  width: 5%;
}

.D1440 {
  height: 8.6%;
  transform: translate(37.4%, 76.5%);
  width: 6.2%;
}

.D1441A1 {
  height: 1.1%;
  transform: translate(87.8%, 49.1%);
  width: 3%;
}

.D1441A2 {
  height: 1.1%;
  transform: translate(91%, 49.1%);
  width: 3.6%;
}

.D1441A3 {
  height: 1.1%;
  transform: translate(94.7%, 49.1%);
  width: 3.5%;
}

.D1441A4 {
  height: 1.1%;
  transform: translate(87.7%, 54%);
  width: 3.3%;
}

.D1441A5 {
  height: 1.1%;
  transform: translate(91%, 54%);
  width: 3.65%;
}

.D1441A6 {
  height: 1.1%;
  transform: translate(94.7%, 54%);
  width: 3.3%;
}

.D1441A7 {
  height: 1.1%;
  transform: translate(87.7%, 55.2%);
  width: 3.3%;
}
.D1441A8 {
  height: 1.1%;
  transform: translate(91%, 55.2%);
  width: 3.7%;
}

.D1441A9 {
  height: 1.1%;
  transform: translate(94.8%, 55.2%);
  width: 3.2%;
}
.D1441A10 {
  height: 1.1%;
  transform: translate(87.7%, 60%);
  width: 3.3%;
}
.D1441A11 {
  height: 1.1%;
  transform: translate(90.9%, 60%);
  width: 3.8%;
}

.D1441A12 {
  height: 1.1%;
  transform: translate(94.8%, 60%);
  width: 3.2%;
}

.D1441A13 {
  height: 1.1%;
  transform: translate(87.7%, 61.2%);
  width: 3.3%;
}

.D1441A14 {
  height: 1.1%;
  transform: translate(90.9%, 61.2%);
  width: 3.8%;
}

.D1441A15 {
  height: 1.1%;
  transform: translate(94.8%, 61.2%);
  width: 3.2%;
}

.D1441A16 {
  height: 1.1%;
  transform: translate(85.4%, 65.8%);
  width: 3.3%;
}

.D1441A17 {
  height: 1.1%;
  transform: translate(88.7%, 65.8%);
  width: 3.6%;
}

.D1441A18 {
  height: 1.1%;
  transform: translate(92.4%, 65.8%);
  width: 3.3%;
}

.D1442 {
  height: 5%;
  transform: translate(78.6%, 30%);
  width: 4.3%;
}
.D1443 {
  height: 6.5%;
  transform: translate(78.6%, 23%);
  width: 4.3%;
}
.D1444 {
  height: 6.5%;
  transform: translate(83.2%, 23%);
  width: 4%;
}
.D1445 {
  height: 5%;
  transform: translate(83.2%, 30%);
  width: 4%;
}
.D1446 {
  height: 6.4%;
  transform: translate(89.8%, 23%);
  width: 4.2%;
}
.D1447 {
  height: 5%;
  transform: translate(89.7%, 30%);
  width: 4.2%;
}
.D1448 {
  height: 5%;
  transform: translate(94.3%, 30%);
  width: 4.2%;
}
.D1449 {
  height: 6.4%;
  transform: translate(94.3%, 23%);
  width: 4.2%;
}
.D1450 {
  height: 9%;
  transform: translate(89.3%, 10.4%);
  width: 10.5%;
}
.D1451 {
  height: 9%;
  transform: translate(81%, 10.4%);
  width: 8%;
}
.D1452 {
  height: 9%;
  transform: translate(75.5%, 10.4%);
  width: 5.4%;
}
.D1453 {
  height: 9%;
  transform: translate(70%, 10.4%);
  width: 5.4%;
}
.D1454 {
  height: 9%;
  transform: translate(65.2%, 10.4%);
  width: 5%;
}
.D1455 {
  height: 9%;
  transform: translate(59.3%, 10.4%);
  width: 5.7%;
}
.D1456 {
  height: 9%;
  transform: translate(53.8%, 10.4%);
  width: 5.7%;
}
.D1457 {
  height: 9%;
  transform: translate(49.2%, 10.4%);
  width: 4.8%;
}
.D1458 {
  height: 9%;
  transform: translate(44.5%, 10.4%);
  width: 4.6%;
}
.D1459 {
  height: 9%;
  transform: translate(39%, 10.4%);
  width: 5.2%;
}
.D1460 {
  height: 9%;
  transform: translate(33.8%, 10.4%);
  width: 4.9%;
}
.D1461 {
  height: 9%;
  transform: translate(28%, 10.4%);
  width: 5.6%;
}

.D1462A1 {
  height: 1.1%;
  transform: translate(11.9%, 13.7%);
  width: 3.2%;
}

.D1462A2 {
  height: 1.1%;
  transform: translate(15%, 13.7%);
  width: 3.8%;
}

.D1462A3 {
  height: 1.1%;
  transform: translate(18.8%, 13.7%);
  width: 3.5%;
}

.D1462A4 {
  height: 1.1%;
  transform: translate(22.3%, 13.7%);
  width: 3.5%;
}

.D1462A5 {
  height: 1.1%;
  transform: translate(11.9%, 14.9%);
  width: 3.2%;
}

.D1462A6 {
  height: 1.1%;
  transform: translate(15%, 14.9%);
  width: 3.8%;
}

.D1462A7 {
  height: 1.1%;
  transform: translate(18.8%, 14.9%);
  width: 3.5%;
}

.D1462A8 {
  height: 1.1%;
  transform: translate(22.3%, 14.9%);
  width: 3.5%;
}

.D1463A {
  height: 7.5%;
  transform: translate(25%, 36.4%);
  width: 9.7%;
}

.D1463B {
  height: 7%;
  transform: translate(25%, 29.4%);
  width: 8.9%;
}
.D1463C {
  height: 6.3%;
  transform: translate(25.3%, 23.3%);
  width: 8.4%;
}
.D1464 {
  height: 6.6%;
  transform: translate(37.7%, 24.2%);
  width: 4%;
}
.D1465 {
  height: 7%;
  transform: translate(38.2%, 36.8%);
  width: 4.8%;
}
.D1466 {
  height: 7%;
  transform: translate(43.3%, 36.8%);
  width: 5%;
}
.D1467 {
  height: 7%;
  transform: translate(48.6%, 36.8%);
  width: 5%;
}
.D1468 {
  height: 7%;
  transform: translate(53.8%, 36.8%);
  width: 5%;
}
.D1469 {
  height: 7%;
  transform: translate(59.1%, 36.8%);
  width: 5%;
}

// .D1470 desk number is a special shape desk. It's coordinates defined in SeatingChart.js file

.D1471 {
  height: 5.5%;
  transform: translate(67.8%, 28.5%);
  width: 3.4%;
}
.D1472 {
  height: 5%;
  transform: translate(67.8%, 23.2%);
  width: 3.4%;
}
.D1473 {
  height: 6.4%;
  transform: translate(60.2%, 24.2%);
  width: 4%;
}
.D1474 {
  height: 6.6%;
  transform: translate(56.2%, 24.2%);
  width: 3.8%;
}
.D1475 {
  height: 6.6%;
  transform: translate(49.6%, 24.2%);
  width: 3.8%;
}
.D1476 {
  height: 6.6%;
  transform: translate(45.5%, 24.2%);
  width: 3.8%;
}
.D1477 {
  height: 2.4%;
  transform: translate(37.7%, 30.9%);
  width: 4%;
}
.D1478 {
  height: 2.4%;
  transform: translate(45.5%, 30.9%);
  width: 3.7%;
}
.D1479 {
  height: 2.4%;
  transform: translate(49.5%, 30.9%);
  width: 4%;
}
.D1480 {
  height: 2.4%;
  transform: translate(56.2%, 30.9%);
  width: 3.8%;
}
.D1481 {
  height: 2.4%;
  transform: translate(60.2%, 30.9%);
  width: 4%;
}
.D1482 {
  height: 5%;
  transform: translate(71.4%, 23.2%);
  width: 4%;
}
.D1483 {
  height: 5.5%;
  transform: translate(71.4%, 28.5%);
  width: 4%;
}

// .D1484 desk number is a special shape desk. It's coordinates defined in SeatingChart.js file

.D1491 {
  height: 5.7%;
  transform: translate(18.7%, 38%);
  width: 6.2%;
}
.D1492 {
  height: 6.2%;
  transform: translate(18.7%, 31.2%);
  width: 6.2%;
}
.D1493 {
  height: 5.4%;
  transform: translate(10.3%, 22.2%);
  width: 4.2%;
}
.D1494 {
  height: 3.4%;
  transform: translate(10.3%, 27.9%);
  width: 4.2%;
}
.D1495 {
  height: 4.7%;
  transform: translate(10.3%, 31.2%);
  width: 4.2%;
}
.D1496 {
  height: 4.9%;
  transform: translate(10.3%, 35.8%);
  width: 4.2%;
}
