@import url('https://fonts.googleapis.com/css?family=Poppins');
@import-normalize;
// ----- START UNSORTED GLOBAL VARIABLES ----- //
body,
pre {
  font-family: 'Poppins' !important;
}
body {
  overflow: hidden;
}
blockquote {
  border-left: 5px solid #e6e6e6;
  padding-block-end: 0.5rem;
  padding-block-start: 0.5rem;
  padding-inline-start: 40px;
  padding-inline-end: 40px;
}
:global(.highlight) {
  background-color: #293042;
  font-weight: 400;
  color: white;
  display: inline-block;
  width: fit-content;
  white-space: nowrap; 
  padding: 0px 4px;
  border-radius: 3px;
}

:global(.capitalize) {
  text-transform: capitalize;
}

:global(.loading-image) {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

:global(.twoFA-fields) {
  /* Chrome, Safari, Edge, Opera */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    padding: 0px;
    font-size: 1.8rem;
    box-shadow: 0px 3px 0 0rem #c2c2c2 !important;
    &:focus {
      box-shadow: 0px 3px 0 0rem #1a565d !important;
    }
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

// :global(.proc-card.accordion-button::after){
//     display: none;
// }

// ----- END UNSORTED GLOBAL VARIABLES ----- //

// ----- START GLOBAL CONTAINER ----- //
:global(.main-container) {
  height: calc(100vh - 54px);
  overflow: auto;
  position: relative;
}

// ----- END GLOBAL CONTAINER ----- //

// ----- START BUTTON CONTAINER ----- //
:global(.buttons-container) {
  margin: 5% auto;
}
// ----- END BUTTON CONTAINER ----- //

// ----- START SUBMIT/CONFIRM BUTTON AND CANCEL/CLOSE BUTTON STYLES ----- //
:global(.submit-button) {
  background-color: #2f56a6;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5;
  min-width: 120px;
  padding: 0.5rem 0.75rem;
  text-align: center;
  text-decoration: none;
  &:hover {
    background-color: #254586;
    outline: none;
  }
  &:active {
    background-color: #1a305d;
    outline: none;
  }
  &:disabled {
    background-color: #e9ecef;
    color: #212529;
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #159ce466;
  }
}
:global(.cancel-button-text) {
  background-color: transparent !important;
  border: none !important;
  color: #4a4a4a;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  margin: 0px 8px;
  min-width: 80px !important;
  padding: 0.5rem 0.75rem !important;
  width: auto;
  &:hover,
  &:focus {
    background-color: #9292923b !important;
    border-radius: 2px;
    box-shadow: none !important;
    color: #252525;
    outline: none;
  }
  &:active {
    background-color: #92929273 !important;
    color: #131212;
    outline: none;
  }
  &:global(.white) {
    color: #fff !important;
  }
}
:global(.cancel-button) {
  background-color: transparent;
  border: none;
  color: #444;
  margin-right: 15px;
  padding: 0px;
  vertical-align: middle;
  &:hover {
    color: #000;
  }
}
// ----- END SUBMIT/CONFIRM BUTTON AND CANCEL/CLOSE BUTTON STYLES ----- //

// ----- START GUIDE ICONS (currently, Calendar & ReportTracking.js only) ----- //
:global(.guide-button) {
  background-color: #2f56a6;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  display: inline;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-left: 1%;
  padding: 0.4rem 0.5rem;
  text-align: center;
  text-decoration: none;
  &:hover {
    background-color: #23417d;
  }
}
// ----- END GUIDE ICONS (currently, Calendar & ReportTracking.js only) ----- //

// ----- START GLOBAL TABLE STYLE ----- //
:global(.list-table) {
  border-collapse: collapse;
  width: 100%;
}
:global(.grip-container) {
  position: absolute !important;
}
:global(.grip-drag) {
  border-left: 2px solid #4a5f96 !important;
}
:global(.list-table tbody, .list-table thead) {
  display: block;
}
// :global(.ReactModal__Overlay.ReactModal__Overlay--after-open) {
//   background-color: rgba(0, 0, 0, 0.25) !important;
//   z-index: 1050;
// }

:global(.grip-handle) {
  z-index: 2 !important;
  &:active {
    background-color: #cdd4e6 !important;
    width: 6px;
  }
  &:hover {
    background-color: #f1f5ff;
    width: 6px;
  }
}
:global(.list-table td):not(:last-child) {
  border: 1px solid #ddd;
  color: #808080;
  font-size: 15px;
  line-height: 1.4;
  padding: 8px;
  width: 80px;
  div {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    &:hover {
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #3d475f;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}

:global(.list-table tr:nth-child(even)) {
  background-color: #f2f2f2;
}
:global(.list-table tr:nth-child(odd)) {
  background-color: #ffffff;
}
:global(.table-tr:hover) {
  background-color: #ececff !important;
  cursor: pointer;
}
:global(.table-tr-overlapping:hover) {
  background-color: #d59d9d !important;
  cursor: pointer;
}
:global(.table-tr-related:hover) {
  background-color: #e5da84 !important;
  cursor: pointer;
}
:global(.table-headers) {
  background-color: #2f56a6;
  color: #fff;
  font-weight: bold;
  line-height: 1.4;
  padding-bottom: 12px;
  padding-top: 12px;
  text-align: left;
  white-space: nowrap;
}
:global(.table-headers th) {
  color: white;
  cursor: pointer;
  padding: 0px 8px;
}
// ----- END GLOBAL TABLE STYLE ----- //

// ----- START LSR APPROVAL STATUS TABLE ROWS STYLES ----- //
:global(.table-tr-status-mark-to-approve) {
  background-color: #fffcc9 !important;

  &:hover {
    background-color: #e5da84 !important;
  }
}

:global(.table-tr-status-mark-to-reject) {
  background-color: #fbd9d3 !important;
  &:hover {
    background-color: #d59d9d !important;
  }
}

// ----- END LSR APPROVAL STATUS TABLE ROWS STYLES ----- //

// ----- START GLOBAL SEARCH BOX AND BUTTON STYLE ----- //
:global(.input-search-box) {
  border: 2px solid #909599;
  border-radius: 5px;
  color: rgb(106, 109, 108);
  outline: none;
  padding: 5px;
  width: 60%;
}
:global(.input-search-box:focus) {
  color: rgb(106, 109, 108);
}
:global(.input-search-button) {
  background-color: white;
  border: 2px solid #2f56a6;
  border-radius: 6px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  height: 50px;
  margin-left: 3%;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
  white-space: nowrap;
}
:global(.input-search-button:hover) {
  background-color: #2f56a6;
  color: white;
}
:global(.input-search-button-disbled) {
  background-color: #e6e6e6;
  border: 2px solid #cacaca;
  border-radius: 6px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  margin-left: 3%;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
  white-space: nowrap;
}
:global(.drop-down-search-container) {
  margin-left: 20px;
  margin-bottom: 10px;
  width: 80%;
}
:global(.drop-down-search) {
  display: flex;
  height: 45px;
  margin-left: 20px;
  margin-top: 10px;
  position: relative;
}
:global(.drop-down-search-option) {
  display: flex;
  margin-left: 20px;
  margin-top: 15px;
}
:global(.drop-down-radio) {
  margin-left: 5px;
  margin-right: 5%;
}
:global(.drop-down-radio label) {
  margin-left: 8px;
}
// ----- END GLOBAL SEARCH BOX AND BUTTON STYLE ----- //

// ----- START GLOBAL POPUP TITLE CONTAINER W/ BACK AND CLOSE ICONS STYLE ----- //
// :global(.popup-title-container) {display: flex;}
:global(.popup-title) {
  flex: 20;
  text-align: center;
}
:global(.popup-detail-title) {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
}
:global(.popup-close-icon) {
  border-radius: 2px;
  cursor: pointer;
  font-size: 1.8rem;
  height: 35px !important;
  width: 35px !important;
  &:hover {
    background-color: #ffffff3b;
  }
  &:active {
    color: #96b3fb;
  }
}
:global(.back-icon) {
  // For the future, need to double check if this class is still needed
  cursor: pointer;
  float: left;
  font-size: 1rem;
  &:hover {
    color: #96b3fb;
  }
}
button {
  &:global(.back-icon) {
    align-items: center;
    background-color: transparent;
    border: 0;
    color: white !important;
    display: flex;
    gap: 0.4rem;
    &:hover {
      background-color: #ffffff3b;
    }
  }
}
:global(.close-icon) {
  cursor: pointer;
  float: right;
  &:hover {
    color: #96b3fb;
  }
}
:global(.download-icon) {
  cursor: pointer;
  margin-right: 0.8rem;
}
// ----- END GLOBAL POPUP TITLE CONTAINER W/ BACK AND CLOSE ICONS STYLE ----- //

// ----- START GLOBAL LOADING SCREEN STYLE ----- //
:global(.loading-container) {
  background-color: #fff;
  background-image: url('../../public/img/city background loading.jpg');
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  h3 {
    font-size: 0.9rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  :global(.loading-logo) {
    margin-bottom: 15vh;
    margin-top: 20px;
  }
}

:global(.dot1) {
  animation: visibility 3s linear infinite;
}
@keyframes visibility {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
:global(.dot2) {
  animation: visibility2 3s linear infinite;
}
@keyframes visibility2 {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
:global(.dot3) {
  animation: visibility3 3s linear infinite;
}
@keyframes visibility3 {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
// ----- END GLOBAL LOADING SCREEN STYLE ----- //

// ----- START App.js ONLY ----- //
.wrapper {
  align-items: stretch;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  #main {
    background-color: #f1f5ff;
    min-height: 100vh;
    min-width: 0;
    transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out,
      margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
    width: 100%;
    #password-warning {
      position: sticky;
      top: 65px;
      width: 100%;
      z-index: 1000;
    }
  }
}
// ----- END App.js ONLY ----- //

// ----- START Sidebar.js ONLY ----- //
#sidebar {
  background-color: #293042;
  direction: ltr;
  max-width: 230px;
  min-width: 230px;
  position: relative;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out,
    margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: 999;
  .logo-container {
    a {
      padding: 0;
      padding-bottom: 5px;
      transition: none;
      z-index: 1;
    }
    .version-num {
      color: rgb(224, 224, 224);
      font-size: 0.75em;
    }
  }
  :global(.accordion-button.collapsed) {
    background-color: #293042;
    border-radius: 0px;
    font-size: 0.9rem;
    padding-left: 0rem;
    padding-right: 2rem;
    &:focus {
      box-shadow: none;
    }
  }
  :global(.accordion-button:not(.collapsed)) {
    background-color: #293042;
    color: #dfdfdf;
    font-size: 0.9rem;
    padding-left: 0rem;
    padding-right: 2rem;
    &:focus {
      box-shadow: none;
    }
  }
  :global(.accordion-button::after) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  a {
    color: #818181;
    display: block;
    font-size: 25px;
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    transition: 0.3s;
    &:hover {
      color: #f1f1f1;
    }
  }
  .video-tutorial-card {
    background-color: #5754ab;
    color: white;
    button {
      color: #5754ab;
    }
  }
}
.sidebar-nav-list {
  &#main-nav {
    flex-shrink: 1;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  overflow-y: auto;
  transition: box-shadow 0.3s ease;
  .sidebar-nav-item {
    align-items: center;
    color: #dfdfdf;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    &.sidebar-subnav-item {
      height: 40px;
    }
    &.active {
      background-color: #1e232f;
      border-left: 2px solid #fff;
      color: #fff;
    }
    .sidebar-nav-icon {
      display: grid;
      min-width: 75px;
      place-items: center;
    }
    .sidebar-nav-title {
      min-width: 175px;
      line-height: 1.4em;
      font-size: 0.9rem;
      padding-right: 10px;
      &.my-tasks {
        min-width: 120px;
      }
    }
    &:hover {
      color: #fff;
    }
  }
}

.has-top-shadow {
  box-shadow: inset 0 7px 15px 3px rgba(0, 0, 0, 0.23);
}

.has-bottom-shadow {
  box-shadow: inset 0 -7px 15px 3px rgba(0, 0, 0, 0.23);
}

.has-top-shadow.has-bottom-shadow {
  box-shadow: inset 0 7px 15px 3px rgba(0, 0, 0, 0.23),
    inset 0 -7px 15px 3px rgba(0, 0, 0, 0.23);
}

.info-icon {
  margin-top: -45px;
  width: 120px;
}
.accordion-body-container-sidebar {
  background-color: #293042;
  padding: 0px 0px 1px !important;
}
.video-button-collapse,
.collapse-container {
  background-color: #4b5879 !important;
  color: white !important;
}

// ----- END Sidebar.js ONLY ----- //

:global(.error-message) {
  align-items: center;
  background-color: #ca4a4a !important;
  border-color: #ca4a4a !important;
  color: white !important;
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
}

// ----- START CUSTOM FIELDS CreateNewLsRequest.js ONLY ----- //
:global(.field-size) {
  font-size: 12px !important;
}
// ----- END CUSTOM FIELDS CreateNewLsRequest.js ----- //

// ----- START MEDIA QUERIES ----- //
@media print {
  :global(body) {
    display: none;
  }
} //printer-friendly style
// ----- END MEDIA QUERIES ----- //

// ----- START SCROLLBAR STYLES ----- //
/* Width */
::-webkit-scrollbar {
  height: 6px;
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3d475f;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
// ----- END SCROLLBAR STYLES ----- //

// ----- START IWOMM BIRTHDAY STYLES ----- //
#sidebar {
  .logo-container {
    #birthday-hat {
      height: 110px;
      left: 66px;
      position: absolute;
      top: 1px;
      width: 110px;
      z-index: 2;
    }
    #confetti {
      height: 300px;
      position: absolute;
      top: -110px;
      width: 300px;
    }
    .anniversary-icon {
      position: absolute;
    }
    &.anniversary {
      padding-top: 90px;
      img {
        height: 42px;
      }
    }
  }
}
// ----- END IWOMM BIRTHDAY STYLES ----- //
