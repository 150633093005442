.table{
    height: 295px;
    margin-bottom: 20px;
    .table-of-content-heading-wrapper{
        color: #fff;
        margin-bottom: 0px;
        font-size: 1.4rem;
        font-weight: 600;
    }
    .tableOfContent {
        border-radius: 10px;
        box-shadow: inset 0px -20px 28px 0px rgba(0,0,0,0.2);
        counter-reset: item;
        height: calc(100% - 40px);
        margin-top: 10px;
        min-width: 200px;
        overflow: auto;
        padding: 0px 15px 15px 15px;
        width: 100%;
        li{
            border-bottom: 1px solid rgb(201, 201, 201);
            display: block;
            font-size: .9rem;
            font-weight: 300;
            padding: 8px;
            :before {
                padding-right: 10px;
            }
            a{
                color: #fff;
                display: block;
                text-decoration: none;
            }
        }
    }
}
