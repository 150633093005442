.default-style {
  border-radius: 8px 8px 0 0;
  bottom: 0;
  height: calc(100vh - 50px);
  left: 0;
  margin: auto;
  max-width: 1690px;
  position: fixed;
  right: 0;
  top: 50px;
  width: 100vw;
  z-index: 9999;

  &.custom-style {
    margin-right: -50%;
    max-height: 80%;
    max-width: 80%;
    min-width: 50%;
    top: 50%;
  }
  &.duplicate-checker-style {
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.29) 0px 0px 18px 0px;
    height: 75%;
    margin-right: -50%;
    max-height: 600px;
    max-width: 950px;
    overflow: hidden;
    padding: 0px;
    top: 55%;
    width: 90%;
  }
  &.create-new-lsr-style {
    background-color: #fff;
    border: none;
    // border-radius: 8px;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.29);
    display: flex;
    flex-direction: column;
    // max-width: 95vw;
    // height: 95vh;
    outline: none;
    overflow: hidden;
    // width: 95vw;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.modalSlideUp {
  animation: slideUp 0.3s ease-in-out forwards;
}

.modalSlideDown {
  animation: slideDown 0.32s ease-in-out forwards;
}

.modalOverlay {
  background-color: #000000bf;
  inset: 0px;
  position: fixed;
  z-index: 1049;
}
