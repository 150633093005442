// Page layout
.agency-page {
  background-color: #bec8ff;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.responsive-borderTopRightRadius {
  background-color: #353c69;
  border-color: #353c69;
  border-top-right-radius: 5rem;
}

.navbar-modal {
  z-index: 3;
  :global(.modal-content) {
    border: none;
    overflow: hidden;
  }
  .form-header-wrapper {
    background-color: #2f56a6;
    color: white;
    display: flex;
    font-size: 1.3rem;
    justify-content: space-between;
    padding: 12px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .responsive-borderTopRightRadius {
    border-top-right-radius: 0 !important;
  }
}

// Background
.skyscrapers-background {
  max-width: 100vw;
  opacity: 0;
  pointer-events: none;
  transform: translateY(50px);
}

.skyscrapers-blur {
  filter: blur(5px);
  transition: filter 0.3s ease-in-out;
}

// Animations
.slide-up {
  animation: slide-up-bounce 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    forwards;
}

.footer-slide-up {
  animation: slide-up-bounce 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s
    forwards;
  p,
  span {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
}

@keyframes slide-up-bounce {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  60% {
    opacity: 1;
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// Form styles
.milestones-form-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 900px;
}

// Form content
.animate-slide-up {
  animation: slide-up 0.3s ease-in-out;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Buttons
.tracker-type-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
  min-height: calc(100vh - 200px);
  padding: 0 1rem;
  width: 100%;
  @media (max-width: 576px) {
    align-items: flex-start;
    gap: 1.5rem;
    justify-content: flex-start;
    min-height: auto;
    padding-top: 2rem;
  }
  .option-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding: 2rem;
    text-align: center;
    transition: all 0.2s ease-in-out;
    width: 100%;
    @media (max-width: 576px) {
      padding: 1.5rem;
    }
    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      transform: translateY(-2px);
    }
    h2 {
      color: #353c69;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      @media (max-width: 768px) {
        font-size: 1.25rem;
      }
    }
    p {
      color: #6b7280;
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 1.5rem;
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }
    .option-btn {
      background-color: #5850ed;
      border-radius: 8px;
      border: none;
      color: white;
      font-weight: 500;
      padding: 0.75rem 1.5rem;
      transition: background-color 0.2s ease-in-out;
      &:hover {
        background-color: darken(#5850ed, 5%);
      }
    }
  }
}

.back-btn {
  align-items: center !important;
  background-color: #fff !important;
  border-radius: 8px !important;
  color: #353c69 !important;
  display: flex !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  gap: 0.5rem !important;
  justify-content: center !important;
  line-height: 1 !important;
  padding: 0.5rem 1rem !important;
  transition: all 0.2s ease-in-out !important;
  &:hover,
  &:active {
    background-color: #f8f9fa !important;
  }
  svg {
    align-items: center !important;
    display: flex !important;
  }
}

// Milestone Form
.milestone-step-number {
  align-items: center;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  display: flex;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  width: 50px;
}

.confirm-section {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.confirm-checkbox {
  align-items: flex-start;
  color: #495057;
  display: flex;
  font-size: 0.95rem;
  line-height: 1.5;
}

.adjust-input {
  height: calc(3.5rem + 2px) !important; // Match the height of other inputs
}

textarea.form-control {
  height: auto;
}

.input-group {
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: calc(3.5rem + 2px);
  .input-group-text {
    background-color: white;
    border-right: none;
    color: #6c757d;
    padding: 0.75rem 1rem;
  }
  .form-control {
    border-left: none;
    box-shadow: none;
    padding-left: 0;
    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }
}

// Report Form
.files-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
  padding: 1rem;
  > div {
    margin-bottom: 1rem;
    position: relative;
  }
}

:global(.file-meta-info) {
  min-width: 150px;
  text-align: right;
}

:global(.card) {
  border: 1px solid #e9ecef;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: #dee2e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
}

.delete-icon {
  color: #dc3545;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  transition: color 0.2s ease-in-out;
  &:hover {
    color: darken(#dc3545, 10%);
  }
}

// File Upload Area
.upload-area {
  margin-bottom: 1rem;
  .form-label {
    color: #495057;
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .drop-zone {
    align-items: center;
    border: 2px dashed #dee2e6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 350px;
    position: relative;
    &:hover {
      background-color: rgba(255, 255, 255, 0.8) !important;
      border-color: #ced4da;
    }
    &.dragging {
      background-color: rgba(255, 255, 255, 0.9) !important;
      border: 2px dashed #5850ed !important;
      box-shadow: 0 0 0 4px rgba(88, 80, 237, 0.1);
      .upload-icon {
        color: #5850ed;
      }
      h5 {
        color: #5850ed;
      }
      .drag-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }
      .drag-icon {
        animation: bounce 0.5s ease-in-out infinite alternate;
        svg {
          height: 80px;
          width: 80px;
        }
      }
      .drag-text {
        color: #5850ed;
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 1rem;
      }
      .drag-subtext {
        color: #6b7280;
        font-size: 1rem;
        margin-top: 0.5rem;
      }
    }
    .upload-icon {
      transition: color 0.2s ease-in-out;
    }
    h5 {
      transition: color 0.2s ease-in-out;
    }
  }

  .browse-btn {
    background-color: #f8f9fa;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    color: #495057;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.5rem 1.5rem;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #e9ecef;
      border-color: #ced4da;
    }
  }
  .file-input {
    display: none;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.search-and-due-date {
  align-items: stretch;
  display: flex;
  gap: 4rem;
  margin: 0 auto;
  max-width: 900px;
  transition: min-height 0.3s ease-in-out;
}

.mini-upload-area {
  position: relative;
  width: 100%;
  .mini-drop-zone {
    align-items: center;
    background-color: white;
    border-radius: 0.375rem;
    border: 1px solid #dee2e6;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    height: calc(3.5rem + 2px); // Match other input heights
    padding: 0.75rem 1rem;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #f8f9fa;
      border-color: #ced4da;
    }
    &.dragging {
      background-color: #f8f9fa;
      border: 2px dashed #5850ed;
      box-shadow: 0 0 0 4px rgba(88, 80, 237, 0.1);
      svg {
        color: #5850ed;
      }
      .file-name {
        color: #5850ed;
      }
    }
    svg {
      color: #6c757d;
      flex-shrink: 0;
      transition: color 0.2s ease-in-out;
    }
    .upload-text {
      flex: 1;
      overflow: hidden;
      .file-name {
        color: #495057;
        display: block;
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: color 0.2s ease-in-out;
        white-space: nowrap;
      }
    }
    .delete-btn {
      background: none;
      border: none;
      color: #dc3545;
      cursor: pointer;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: darken(#dc3545, 10%);
      }
    }
  }
  .file-input {
    display: none;
  }
}

.mini-drop-zone[data-dragging='true'] {
  background-color: #f8f9fa;
  border-color: #5850ed;
  border-style: dashed;
}

.new-file {
  animation: new-file-highlight 2s ease-in-out;
}

@keyframes new-file-highlight {
  0% {
    background-color: rgba(88, 80, 237, 0.2);
  }
  100% {
    background-color: transparent;
  }
}

.uploaded-files-heading {
  font-size: 1.1rem;
}

.due-date-container {
 .step-number {
    align-items: center;
    border-radius: 100%;
    border: 2px solid #fff;
    color: #fff;
    display: flex;
    flex: 0 0 auto;
    font-size: 1rem;
    font-weight: bold;
    height: 50px;
    justify-content: center;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    width: 50px;
  }
  &.selected {
    .step-number {
      background-color: #353c69;
      border-color: #353c69;
    }
  }
}

.search-container {
  align-items: center;
  display: flex;
  gap: 2rem;
  max-width: 900px;
  position: relative;
  width: 100%;
  .step-number {
    align-items: center;
    border-radius: 100%;
    border: 2px solid #fff;
    color: #fff;
    display: flex;
    flex: 0 0 auto;
    font-size: 1rem;
    font-weight: bold;
    height: 50px;
    justify-content: center;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    width: 50px;
  }
  &.selected {
    .step-number {
      background-color: #353c69;
      border-color: #353c69;
    }
  }
}