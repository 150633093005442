.ComboBox {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 0 0 8px 8px;
  border-top: none;
  cursor: pointer;
  list-style: none;
  margin-top: 0;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  width: 100%;
  z-index: 2;

  // Styling the scrollbar
  &::-webkit-scrollbar {
    border-radius: 4px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border: 2px solid white;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0 8px 8px 0;
  }

  li {
    padding: 0;

    button {
      padding: 0.5rem 1rem;
      text-align: left;
      white-space: normal !important;
      width: 100%;
      word-wrap: break-word;
    }

    &:last-child button {
      border-radius: 0 0 8px 8px;
    }

    &:hover {
      background-color: #719ece;
      color: white;
    }
  }
}
