// ----- START global styles ----- //
:global(.anchor) {
  margin-top: -120px;
  padding-top: 120px;
}

:global(.ql-size-small) {
  font-size: small;
}
// ----- END global styles ----- //

// ----- START Shared w/ PrinterFriendlyPage.js WikiPage.js ----- //
.page {
  composes: page from './Wiki.module.scss';
  .watermark {
    background-image: url(../../../public/img/clex_watermark.png);
    background-size: contain;
    border-radius: 8px;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    height: calc(100vh - 140px);
    overflow: auto;
    padding: 20px;
    &.reduce-height {
      height: calc(100vh - 238px);
    }
  }
  .disclaimer {
    background-color: #efefef;
    border-radius: 8px;
    padding: 20px;
    .disclaimer-button {
      background-color: #545b62;
      border: none;
      border-radius: 3px;
      color: #fff;
      font-size: 0.8rem;
      padding: 4px 10px;
    }
  }
  .wiki-header {
    margin-top: 35px;
    div:first-child {
      font-size: 1.2em;
      font-weight: 600;
      text-align: left;
    }
    .memo-message {
      font-size: 0.85rem;
    }
    span {
      color: #007bff;
      cursor: pointer;
      padding: 5px;
    }
    .drafter-names {
      font-style: italic;
    }
  }
  .div-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
  }
  .tag-link:not(:last-of-type) {
    margin-right: 5px;
  }
}
// ----- END Shared w/ PrinterFriendlyPage.js WikiPage.js ----- //

// ----- START WikiPage.js ----- //
.edit-page-button {
  background-color: #5750ec !important;
  border: 0px;
  border-radius: 3px;
  color: #fff;
  font-size: 0.9rem !important;
  padding: 4px 10px !important;
  margin-left: 10px;
}

.dropdown-menu-wrapper {
  background-color: #e4e4e4;
  border: none !important;
  box-shadow: 1px 1px 6px 0px #0000007a;
  :global(.dropdown-item) {
    color: #212529;
    font-size: 0.9rem;
    font-weight: 300;
  }
  :global(.dropdown-item):focus {
    border: 0px !important;
  }
}
// ----- END WikiPage.js ----- //
