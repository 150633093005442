.wrap-left-to-right-1,
.wrap-left-to-right-2,
.wrap-top-to-bottom-1,
.wrap-bottom-to-top-1,
.wrap-right-to-left-1,
.wrap-right-to-left-2,
.upper,
.lower,
.footstep {
  position: relative;
}

.upper,
.lower {
  align-items: flex-start;
  display: flex;
  margin: -3px;
  top: 10px;
  width: 100%;
}

.lower {
  margin-left: 12px;
}
.upper {
  margin-bottom: 4.5px;
}

.footstep {
  height: 2.5px;
  margin: 0 11px;
  opacity: 0;
  width: 5px;
}

@media (min-width: 1919px) {
  .wrap-left-to-right-1 {
    left: 100px;
    top: 277px;
  }
  .wrap-left-to-right-2 {
    left: 70px;
    top: 714px;
  }
  .wrap-top-to-bottom-1 {
    left: 308px;
    top: 680px;
  }

  .wrap-bottom-to-top-1 {
    right: 240px;
    top: 65px;
  }

  .wrap-right-to-left-1 {
    right: 20px;
    top: 99px;
  }
  .wrap-right-to-left-2 {
    right: 190px;
    top: 531px;
  }
}

@media (max-width: 1919px) {
  .wrap-left-to-right-1 {
    left: 100px;
    top: 230px;
  }
  .wrap-left-to-right-2 {
    left: 70px;
    top: 592px;
  }
  .wrap-top-to-bottom-1 {
    left: 255px;
    top: 570px;
  }

  .wrap-bottom-to-top-1 {
    right: 200px;
    top: 90px;
  }

  .wrap-right-to-left-1 {
    right: 20px;
    top: 86px;
  }
  .wrap-right-to-left-2 {
    right: 160px;
    top: 445px;
  }
}
