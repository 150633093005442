/* FONT IMPORTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

@mixin reportTracking($fontColor) {
  color: $fontColor;
}

:root {
  --filter-pending: #5a70ac;
  --filter-approved: #009688;
  --filter-progress: #ff9800;
  --filter-completed: #636d77;
  --filter-delivered: #2196f3;
  --filter-withdrawn: #f44336;
}

.citation-request-filters {
  min-width: 550px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  input,
  span {
    padding: 5px;
  }
  // styles in renderHeader() function
  .calendarHeader {
    align-items: center;
    color: #566fb1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 500;
    font-size: 1.2rem;
    height: 65px;
    justify-content: space-between;
    margin: 0 !important;
    min-width: 405px;
    padding: 0px 15px 0px 15px;
    width: 100%;
    .current-month-container {
      border-radius: 4px;
      cursor: pointer;
      padding: 0 0.5rem;
      transition: 0.3s;
      &:hover,
      &:focus {
        background-color: #f2f5fe;
        box-shadow: none;
      }
      &:active {
        background-color: #eaecf7;
      }
      .current-month-wrapper {
        font-size: 30px;
        padding: 0px !important;
      }
      .current-year-wrapper {
        font-size: 28px;
        font-weight: 200;
        padding: 0px !important;
      }
    }
    .arrow-month-selection {
      border-radius: 100px;
      color: #586eb2;
      cursor: pointer;
      font-size: 0.9rem;
      height: 42px;
      padding: 0;
      width: 42px;
      &:hover,
      &:focus {
        background-color: #f2f5fe;
        box-shadow: none;
      }
      &:active {
        background-color: #eaecf7;
      }
    }
    .change-monthYear-container {
      width: 332px;
      .select-box {
        border-color: #dde0e3;
        border-radius: 0.25rem;
        color: #495057;
        cursor: pointer;
        font-size: 1.2rem;
        line-height: 1.5;
        margin-left: 1%;
        margin-right: 1%;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
          -webkit-box-shadow 0.15s ease-in-out;
        &:focus {
          border-color: #719ece;
          box-shadow: 0 0 10px #719ece;
          outline: none !important;
        }
      }
      .submit {
        background-color: #566fb1;
        border: 1px solid #fff;
        border-radius: 0.25rem;
        color: white;
        font-size: 1rem;
        margin-left: 3%;
        margin-right: 3%;
        width: 15%;
        &:hover {
          background-color: #586eb2;
          border: 1px solid #455a8f;
        }
        &:active {
          background-color: #455a8f;
        }
      }
      .close-monthYear-input-fields {
        background-color: #fff;
        border-radius: 100%;
        color: #002164;
        cursor: pointer;
        line-height: 1rem;
        padding: 0px;
        &:active {
          background-color: #d2d2d2;
        }
      }
    }

    .calendar-view-toggle {
      color: #566fb1 !important;
      border-color: #566fb1 !important;
      margin-right: 15px;
      &:hover {
        background-color: #5770b1 !important;
        border-color: #5770b1 !important;
        color: #fff !important;
      }
    }
    .calendar-stack-btn {
      color: #566fb1 !important;
      margin: 0 5px;
      padding-inline: 0.25rem;
    }
    .dropdown-filter-toggle {
      border-radius: 100px;
      color: #586eb2;
      font-size: 0.9rem;
      height: 42px;
      padding: 0;
      width: 42px;
      &:hover,
      &:focus {
        background-color: #f2f5fe;
        box-shadow: none;
      }
      &:active {
        background-color: #eaecf7;
      }
      > button {
        color: #566fb1 !important;
        padding-inline: 0.25rem;
        &:focus {
          box-shadow: none !important;
        }
      }
    }
    .create-report-button {
      background-color: #586eb2;
      color: #fff;
      margin-left: 10px;
      &.non-leg-staff {
        visibility: hidden;
      }
      &:hover {
        background-color: #002164;
      }
    }
  }

  // // styles in renderDays() function
  .daysRows {
    align-content: center;
    color: #586eb2;
    display: flex;
    flex-wrap: wrap;
    font-size: 80%;
    height: 24px;
    width: 100%;
    .daysCol {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      min-height: 1px;
      margin: 0;
      position: relative;
      width: 100%;
      padding-right: 12px;
      text-align: center;
      z-index: 1;
    }
  }
  .row-container {
    border-top: 1px solid #dce2e9;
    display: grid;
    height: calc(100vh - 144px);
    :last-child {
      border-bottom: 0px !important;
    }
    // // styles in renderCells() function
    .temp-row {
      border-bottom: 1px solid #eee;
      display: flex;
      flex-wrap: wrap;
      margin: 0 !important;
      padding: 0;
      width: 100%;
      .colCell,
      .colCellSelected,
      .colCellDisabled {
        background: #fff;
        display: inline-block;
        flex-basis: 0;
        flex-grow: 1;
        margin: 0;
        max-width: 100%;
        min-height: 1px;
        overflow: hidden;
        position: relative;
        transition: 0.25s ease-out;
        width: 100%;
        .cellNumber {
          font-size: 65%;
        }
        .bg {
          color: #1a8fff;
          font-size: 8em;
          font-weight: 700;
          letter-spacing: -0.07em;
          line-height: 1;
          opacity: 0;
          position: absolute;
          right: -0.05em;
          top: -0.3em;
          transition: 0.25s ease-out;
        }

        .citation-request-calendar-day-container {
          left: 0;
          right: 0;
          margin-top: -18px;
          position: absolute;
          text-align: center;

          .citation-num-container {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 30%;
            width: 100px;
            z-index: 0;
            .total-citation-requests-num {
              font-size: 1.2rem;
            }
          }
          canvas {
            margin: auto;
            z-index: 99999;
          }
        }
      }
      .colCell:hover {
        background: #f9f9f9;
        transition: 0.5s ease-out;
      }
      .colCellSelected {
        border-left: 1px solid #1a8fff;
        border-right: 1px solid #1a8fff;
        box-shadow: 0 0 5px #719ece;
      }
      .colCell:hover,
      .colCellSelected {
        > .bg {
          //reveals large date number in calendar cell
          opacity: 0.05;
          transition: 0.5s ease-in;
        }
      }
      .colCellDisabled {
        color: #ccc;
        pointer-events: none;
      }
    }
  }
}

.popover-outer-container {
  > div {
    border: none !important;
  }
  .popover-container {
    border-radius: 6px !important;
    box-shadow: 0 3px 10px -4px rgba(51, 62, 73, 0.18),
      0 6px 17px 2px rgba(51, 62, 73, 0.08);
    overflow: hidden;
    width: 205px !important;
  }
}
.filter-dropdown-menu {
  min-width: 200px !important;
  background-color: #fff !important;
  font-size: 14px !important;
  box-shadow: 0 3px 10px -4px rgba(51, 62, 73, 0.18),
    0 6px 17px 2px rgba(51, 62, 73, 0.08);
  border: none !important;
  li {
    padding: 6px 16px;
    &:first-child {
      padding: 0 !important;
    }
  }
}
.color-filter-container {
  cursor: pointer;
  display: block;
  font-size: 0.9rem;
  position: relative;
  padding-left: 35px;
  user-select: none;
  &.pending {
    color: var(--filter-pending);
  }
  &.approved {
    color: var(--filter-approved);
  }
  &.in-progress {
    color: var(--filter-progress);
  }
  &.completed {
    color: var(--filter-completed);
  }
  &.delivered {
    color: var(--filter-delivered);
  }
  &.withdrawn {
    color: var(--filter-withdrawn);
  }

  &.pending:hover input ~ .color-filter-checkmark,
  &.pending input:checked ~ .color-filter-checkmark.pending {
    background-color: var(--filter-pending);
  }
  &.approved:hover input ~ .color-filter-checkmark,
  &.approved input:checked ~ .color-filter-checkmark.approved {
    background-color: var(--filter-approved);
  }
  &.in-progress:hover input ~ .color-filter-checkmark,
  &.in-progress input:checked ~ .color-filter-checkmark.in-progress {
    background-color: var(--filter-progress);
  }
  &.completed:hover input ~ .color-filter-checkmark,
  &.completed input:checked ~ .color-filter-checkmark.completed {
    background-color: var(--filter-completed);
  }
  &.delivered:hover input ~ .color-filter-checkmark,
  &.delivered input:checked ~ .color-filter-checkmark.delivered {
    background-color: var(--filter-delivered);
  }
  &.withdrawn:hover input ~ .color-filter-checkmark,
  &.withdrawn input:checked ~ .color-filter-checkmark.withdrawn {
    background-color: var(--filter-withdrawn);
  }
  input {
    cursor: pointer;
    height: 0;
    position: absolute;
    opacity: 0;
    width: 0;
    &:checked ~ .color-filter-checkmark:after {
      display: block;
    }
  }
  .color-filter-checkmark {
    border-radius: 30px;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
    &.pending {
      border: 2px solid var(--filter-pending);
    }
    &.approved {
      border: 2px solid var(--filter-approved);
    }
    &.in-progress {
      border: 2px solid var(--filter-progress);
    }
    &.completed {
      border: 2px solid var(--filter-completed);
    }
    &.delivered {
      border: 2px solid var(--filter-delivered);
    }
    &.withdrawn {
      border: 2px solid var(--filter-withdrawn);
    }
    &:after {
      border: solid white;
      border-width: 0 3px 3px 0;
      content: '';
      display: none;
      height: 10px;
      left: 9px;
      position: absolute;
      top: 5px;
      transform: rotate(45deg);
      width: 5px;
    }
  }
  .filter-popover-user-guide {
    color: #c0c0c0;
    position: absolute;
    right: 0px;
    top: 5px;
  }
}
