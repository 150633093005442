/* Style for service popup window */
.modalOverlay {
  background-color: #000000bf;
  inset: 0px;
  position: fixed;
  z-index: 1049;
}

.popup-title-container {
  align-items: center;
  background-attachment: fixed;
  background-color: #293042;
  // background-image: url('/img/pattern-modal-header.png');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: repeat-x;
  color: white;
  display: flex;
  height: 85px;
  justify-content: space-between;
  padding-inline: 22px;
  position: sticky;
  top: 0;
  z-index: 1;
  .popup-title-wrapper {
    h1 {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.4em;
      margin-bottom: 0px !important;
    }
    h2 {
      font-size: 1rem;
      font-weight: 300;
      margin-bottom: 0px !important;
      margin-right: 0;
    }
  }
}

.report-modal-style {
  background-color: #fff;
  bottom: auto;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.29);
  inset: 50% auto auto 50%;
  left: 50%;
  right: auto;
  margin-right: -50%;
  max-width: 700px;
  outline: none;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 95%;
}

/* Relates to commented out code in ReportServicePopup.js */
.popup-filter-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px 32px;
  #popover-checkbox-toggle {
    background-color: transparent !important;
    border: 1px #566fb1 solid;
    border-radius: 4px !important;
    color: #566fb1 !important;
    font-size: 0.9rem;
    &:global(.active) {
      background-color: #566fb1 !important;
      color: #fff !important;
    }
  }
  .popup-filter-dropdown-body {
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.29);
    padding: 16px 10px;
    text-align: center;
    /* Hide the browser's default checkbox */
    .report-filter-container {
      color: #838383;
      cursor: pointer;
      display: block;
      font-size: 0.8rem;
      -moz-user-select: none;
      -ms-user-select: none;
      padding-left: 30px;
      position: relative;
      text-align: left;
      user-select: none;
      -webkit-user-select: none;
      /* On mouse-over, add a grey background color */
      &:hover input ~ .report-filter-checkmark {
        background-color: #566fb1;
        transition-duration: 0.4s;
      }
      /* Create a custom checkbox */
      .report-filter-checkmark {
        background-color: #f4f8ff;
        border-radius: 1px;
        box-shadow: inset 0px 0px 3px 1px rgba(68, 68, 68, 0.25);
        height: 16px;
        left: 5px;
        position: absolute;
        top: 3px;
        width: 16px;
        &:after {
          border: solid white;
          border-width: 0px 2px 2px 0;
          content: '';
          display: none;
          height: 9px;
          left: 6px;
          position: absolute;
          top: 2px;
          transform: rotate(45deg);
          width: 5px;
        }
      }
      input {
        cursor: pointer;
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;
        &:checked ~ .report-filter-checkmark {
          background-color: #566fb1;
        }
        &:checked ~ .report-filter-checkmark:after {
          display: block;
        }
      }
    }
    .apply-button-filter {
      color: #fff !important;
      background-color: #355494 !important;
      border: none;
      border-radius: 4px;
      margin-top: 18px;
      padding: 5px 10px;
      width: 80% !important;
      &:hover {
        background-color: #002164 !important;
        transition-duration: 0.4s;
      }
    }
  }
}
