// ----- START Dashboard.js STYLES ----- //

.dashboard-welcome-container {
  background-image: linear-gradient(
      180deg,
      rgba(69, 64, 162, 0.9),
      rgba(69, 64, 162, 0.9)
    ),
    url('../../../../public/img/city background loading.jpg');
  background-size: cover;
  min-height: 270px;
  overflow: hidden;
  position: relative;
  border: none;
  .whats-new-button {
    font-size: 1.4rem;
    text-align: right;
    .video-tutorials {
      font-size: 1rem;
    }
    &:active {
      background-color: #92929273;
    }
  }
  #hb-banner {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  &.anniversary {
    min-height: 650px;
  }
  .event-list-item-wrapper {
    background-color: #0000001a !important;
    border-radius: 1rem !important;
    .date-card-container {
      background-color: #7d78d8a1;
      border-radius: 0.5rem;
      color: white;
      min-width: 120px;
      transition: all ease-in 5s;
      .date-card-head {
        font-size: 0.9rem;
      }
    }
    &:hover {
      box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
    }
    &:last-child {
      margin-bottom: 1.5rem;
    }
  }
  .list-group {
    overflow-y: auto;
    height: 300px;
    padding-right: 10px;
  }
}
.hb-heading {
  font-family: 'Lobster', cursive;
}
// ----- END Dashboard.js STYLES ----- //

// ----- START ReportTables.js STYLES ----- //
.flex-table {
  thead {
    display: table;
    table-layout: fixed;
    width: 100%;
    tr {
      th.report-dashboard-table-headers {
        background-color: #3b3a54;
        border-bottom-width: 0px !important;
        border-right: 1px solid white;
        color: white;
        font-size: 0.7rem;
        font-weight: bold;
        position: sticky;
        text-align: center;
        top: 0;
        &:nth-child(1) {
          width: 24%;
        }
        &:nth-child(2) {
          width: 19%;
        }
        &:nth-child(3) {
          width: 14%;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  tbody {
    border-top: none !important;
    display: block;
    max-height: 220px;
    overflow-y: auto;
    tr {
      display: table;
      margin-bottom: 0.5rem;
      table-layout: fixed;
      width: 100%;
      &:nth-of-type(odd) {
        background-color: #fff;
      }
      &:nth-of-type(even) {
        background-color: #f2f2f2;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      td {
        border-top: 1px solid rgba(189, 189, 189, 0.38);
        color: black;
        // cursor: pointer;
        font-size: 11px;
        height: 60px;
        line-height: 15px;
        padding: 0.2rem !important;
        text-align: center;
        vertical-align: middle;
        &:nth-child(1) {
          width: 24%;
        }
        &:nth-child(2) {
          width: 19%;
        }
        &:nth-child(3) {
          width: 14%;
        }
      }
    }
  }
}
// ----- END ReportTables.js STYLES ----- //

// ----- START LsRequestTable.js STYLES ----- //
.ls-dashboard-table {
  height: 100% !important;
  thead {
    display: table;
    table-layout: fixed;
    width: 100%;
    tr {
      th.ls-dashboard-table-headers {
        background-color: #3b3a54;
        color: #fff;
        font-size: 0.7rem;
        text-align: center;
      }
    }
  }
  tbody {
    border-top: none !important;
    // cursor: pointer;
    display: block;
    font-size: 0.7rem;
    // max-height: 360px;
    overflow-y: auto;
    tr {
      display: table;
      margin-bottom: 0.5rem;
      table-layout: fixed;
      width: 100%;
      &:nth-of-type(odd) {
        background-color: #fff;
      }
      &:nth-of-type(even) {
        background-color: #f2f2f2;
      }
      td {
        border: none;
        border-bottom: 1px solid #ccc;
        border-top: 1px solid rgba(189, 189, 189, 0.38);
        height: 60px;
        text-align: center;
        width: 90px;
        vertical-align: middle;
        cursor: pointer;
      }
      td,
      td pre {
        color: black;
        font-size: 11px;
        line-height: 15px;
      }
    }
  }
}
// ----- END LsRequestTable.js STYLES ----- //

// ----- START AllMyLs.js, MyActiveLs.js SHARED STYLES ----- //
.widget-container {
  // ONLY THIS CLASS IS SHARED WITH ReportsWidget.js
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  height: 300px;
  overflow: hidden;
  .table-header-container {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding: 0px 22px;
    h3 {
      font-size: 1rem !important;
      margin-bottom: 0px;
    }
    a {
      background-color: transparent;
      border: none;
      color: #3f4246;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0.03rem;
      padding: 4px 0px 4px 8px;
      text-decoration: none;
      transition-duration: 0.2s;
      &:hover {
        background-color: #4540a2;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}
// ----- END AllMyLs.js, MyActiveLs.js SHARED STYLES ----- //

@media only screen and (max-width: 500px) {
  .dashboard-welcome-container .whats-new-button {
    margin-left: 10px;
    margin-top: 10px;
    position: relative;
    text-align: left;
  }
}
