.login-title {
  background-color: #2f56a6;
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 0px;
  padding: 1rem 1rem;
  position: absolute;
  top: 0;
  width: 100%;
}
.create-new-user-form-wrapper {
  padding: 20px;
}
.create-new-user-modal-footer {
  background-color: #dcdcdc;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 8px;
  position: sticky;
  width: 100%;
}

// .input-text-box {
//   background-color: #e8e8e8;
//   border: none;
//   border-radius: 4px;
//   color: #574751;
//   font-size: 16px;
//   height: 40px;
//   line-height: 24px;
//   margin-bottom: 20px;
//   padding-left: 8px;
//   width: 100%;
//   &:focus{
//     box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.2);
//     outline: none;
//     transition: 0.2s ease;
//   }
//   &.multi-select{
//     height: 70%;
//     overflow-x: auto;
//   }
// }

// .input-name-not-empty {
//   font-family: Arial, Helvetica, sans-serif;
//   font-size: 1rem;
//   font-weight: 500;
//   margin-bottom: 0px;
//   opacity: 1.0;
//   transition-duration: 0.5s;
//   transition-timing-function: ease;
// }

.instructions {
  font-size: 0.8rem;
}

.success-message {
  font-size: 20px;
  margin-top: 50px;
}

.inline-block {
  display: inline-block;
  font-size: 14px;
}

.login-button {
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  margin-right: 5%;
  margin-top: 10px;
  width: 100%;
}
.login-button-container {
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}
.accessContent {
  display: flex;
  flex-direction: column;

  & > * {
    margin: auto;
  }
}
.unlock {
  width: fit-content;
}
