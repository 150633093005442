/* style for due reports detail information popup */
/* .due-reports-detail-title-container {
    display: flex;
} */


.due-reports-list-locallaw{
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    opacity: 0.8;
}

.report-details {
    list-style-type: none;
    padding: 0;
    margin: 0;
}   

.back-button {
    border: 2px solid #6c7ae0;
    background-color: transparent;
    color: black; 
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 6px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    font-size: 15px;
    opacity: 0.8;
    font-family: Arial, Helvetica, sans-serif;
}

.back-button:hover {
    background-color: #6c7ae0;
    color: white;
}

.modal-header {
    composes: modal-header from "./CreateNewReportDdl.module.scss";
}

.modal-header-citations {
    background-color: #42293D;
}

.modal-footer {
    flex-direction: column;
    button:first-child {
        margin-right: 0px;
        float: none;
      }
    composes: modal-footer from "./CreateNewReportDdl.module.scss";
    span {
        font-size: 0.75rem;
        margin-top: 0.2rem;
    }
}

.modal-footer-button-container {
    flex-direction: row;
    width: 100%;
    button:first-child {
        margin-right: auto;
        float: left;
      }
    button:nth-child(2) {
        margin-left: auto;
        float: right;
      }
}

.footer-button {
    composes: footer-button from "./CreateNewReportDdl.module.scss";
}

.cancel-button {
    background: #ca4a4a !important;
    composes: footer-button;
  }

.disabled{
    background: #6E81A9 !important;
    &:hover{
        cursor: not-allowed;
    }
}

.report-fields {
    composes: report-fields from "./CreateNewReportDdl.module.scss";
    .report-field-column {
        min-width: 50%;
        .report-field-files {
            max-height: 13rem;
            overflow: auto;
        }
    }
    .report-field-column > div > p {
        font-weight: bold;
        margin-bottom: 5px;
    }
    .report-field-column > div {
        margin-bottom: 10px;
        &:last-of-type{ padding-bottom:15px; }
    }
}

.report-fields > div:first-child {
    padding-right: 20px;
}

@media screen and (max-width: 550px) {
    .report-fields {
        flex-direction: row;
    }
}

.input-text-title{
    display: inline-block;
}

.user-guide-field-icon{
    margin-left: 4px;
    color: #838383;
  }

.popover-container {
    box-shadow: 0px 20px 40px 0px rgba(0,0,0,0.2);
    border-radius: 6px !important;
    padding: 6px;
    div{
        font-size:.7rem;
    }
}

.popover-outer-container{
    :global(.arrow::before){
        border-width: 0px !important;
    }
> div {
    border: none !important;
    }
}
.proclamation-contact-person{
    align-items: center;
    display: block;
    gap: 0.2rem;
    text-decoration: none;
}