// ----- START Wiki.js ----- //
.breadcrumb{
  margin-left: 300px;
  margin-right: 20px;
  max-width: 960px;
  ol{
    background-color: #f1f5ff;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: .75rem 0rem;
  }
}
// ----- END Wiki.js ----- //

// ----- START WikiHome.js ----- //

.alphabet-buttons-container{
  margin: auto;
  text-align: center;
  width: 80%;
  .heading{
    color: #fff;
    font-size: 1.2rem;
    font-weight: 200;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .alphabet-buttons{
    background-color: transparent;
    border: none;
    color: #5a42b3;
    height: 30px;
    font-size: 1.1rem;
    font-weight: bold;
    transition: transform .2s;
    width: 30px;
    &.selected-button{
      border: 1px solid #5a42b3;
      z-index: 2;
    }
    &.selected-button, &:hover{
      background-color: #fff;
      border-radius: 30px;
      color: #501796;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #501796;
      transform: scale(1.5);
    }
    &:focus{
      outline: none !important;
    }
    &:active{
      background-color: #fff;
      border-radius: 30px;
      border: 0px !important;
      color: #501796;
      font-weight: 600;
      outline: none !important;
      transform: scale(1.5);
    }
  }
}
.arrow-container{
  align-items: center;
  color: #5a42b3;
  cursor: pointer;
  display: flex;
  height: 60px;
  padding: 4px 10px;
  position: absolute;
  transition: ease-in .3s;
  user-select: none;
  &:hover{
    background-color: #5750ec;
    color: #f1f5ff;
  }
  &:active{
    background-color: #4e48c2;
  }
  &.right{
    border-radius: 4px 0px 0px 4px;
    margin-top: -45px;
    right: 0;
  }
  &.left{
    border-radius: 0px 4px 4px 0px;
    left: 0;
    margin-top: -15px;
  }
}

.subject-results-wrapper{
  align-items: center;
  background-color: #dce1ec;
  display: flex;
  height: 54px;
  overflow-x: auto;
  width: 100%;
  ul{
    display: flex;
    color: #fff;
    font-weight: 500;
    list-style: none;
    margin-bottom: 0px !important;
    padding: 0px;
  }
  li{
    min-inline-size: fit-content;
  }
  a{
    background-color: #5750ec;
    border-radius: 20px;
    font-size: .9rem;
    margin: 0px 10px;
    padding: 6px 14px;
    &:hover{
      background-color:#37239d;
    }
  }
}

.recent-article-heading {
  color: #37239d;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  text-align: center;
}

.pages-list{
  list-style-type: none;
  padding-inline-start: 0px;
  .card-body-gray-topics{
    background-color: #28237b !important;
    border-radius: 8px;
    border: 0px !important;
    color: #6b6b6b;
    margin-bottom: 15px;
    padding: 15px 10px;
    a{
      color: #fff;
      font-weight: 500;
      text-decoration: none !important;
    }
  }
}

.clex-create-new-button{
  background: #5770B1 !important;
  border: 0px !important;
  border-radius: 50% !important;
  bottom: 20px;
  box-shadow: 
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: white;
  height: 75px;
  padding: 0px 12px;
  position: fixed;
  right: 20px;
  transition: all .3s;
  width: 75px;
  z-index: 999;
  
  &:hover {
    background-color:#3e5182 !important;
  }
  &:active{
    background-color:#324169 !important;
  }
  &:global(.dropdown-toggle::after){
    display: none !important;
  }
}
.create-dropdown-menu{
  background-clip: padding-box !important;
  background-color: #fff !important;
  border: 1px solid rgba(0,0,0,.15) !important;
  border-radius: .2rem !important;
  color: #6c757d !important;
  font-size: .825rem !important;
  list-style: none !important;
  margin: 0 !important;
  min-width: 10rem !important;
  padding: .5rem 0 !important;
  position: absolute !important;
  text-align: left !important;
  z-index: 1000 !important;
  .create-dropdown-menu-item{
    background-color: transparent;
    border: 0;
    color: #495057;
    clear: both;
    cursor: pointer;
    display: block;
    font-weight: 400;
    padding: .35rem 1.5rem;
    text-align: inherit;
    white-space: nowrap;
    width: 100%;
    &:active{
      background-color: #2a3142;
      color: white;
    } 
  }
}

.footer-clex-home{
  background-color: rgb(40, 35, 123);
  color: #fff;
  padding: 60px 35px;
  .footer-logo{
    text-align: center;
  }
}

@media only screen and (max-width: 720px){
  .recent-article-heading {
    margin-top: 2rem;
  }
}

// ----- END WikiHome.js ----- //

// ------ START WikiDisclaimerPopup.js ----- //
.footer-message-container{
  align-items: center;
  display: flex;
  justify-content: space-between;
  .message-footer{
    font-size: .8rem;
    font-weight: 600;
    max-width: 60%;
    a{
      color: #28237B !important;
      &:hover{
        text-decoration: none !important;
        text-shadow: 0 0 0.01px black;
      }
    }
  }
}

@media only screen and (max-width: 559px){
  .footer-message-container{
    display: grid;
  }
  .message-footer{
    margin-bottom: 10px;
    text-align: center;
    max-width: 100% !important;
  }
}
// ----- END WikiDisclaimerPopup.js ----- //

// ----- START FeaturedCarousel.js ----- //
.carousel-background{
  background-color: #2E395D;
  border-radius: 8px;
  height: 300px;
}
// ----- END FeaturedCarousel.js ----- //

@media (max-width: 768px) { 
  .arrow-container{
    display: none;
  }
  .alphabet-buttons-container{
    width: 100%;
  }
}

@media (max-width: 1236px) { 
  .arrow-container.left{
    margin-top: 1px;
  }
  .arrow-container.right{
    margin-top: -59px;
  }
}

// ----- STYLES COMPOSED IN OUR STYLESHEETS
// ----- PageForm, SubjectForm, SubjectPage, TagForm, and WikiPage.module.scss composes .page from this file ----- //
// ----- Unsure if the ol also influences these files ----- //
.page{
  margin-left: 300px;
  max-width: 1140px;
  padding-right: 20px;
  ol{
    background-color: #f1f5ff;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: .75rem 0rem;
  }
}