.search-bar-container{
    background-color: #fff !important;
    border-radius: 30px;
    box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.13);
    flex-wrap: inherit !important;
    margin-inline: auto;
    max-width: 720px;
    .search-by-button{
        background-color: #5750ec;
        border-radius: 30px 0px 0px 30px;
        color: #fff;
        font-size: 12px;
        padding-right: 4px;
        &:hover, &:focus{
            background-color: #4b45c9 !important;
            box-shadow: none !important;
            color: #fff;
        }
        &:disabled{ opacity: 1; }
    }
    .search-by-dropdown{
        background-color: #5750ec !important;
        color: #fff !important;
        padding: 0px 8px !important;
        &:active, &:focus, &:hover{
            background-color: #4b45c9 !important;
            box-shadow: none !important;
        }
    }
    .clex-search-bar-wrapper-top{
        height: 55px;
        &:focus{ margin-left: 0px !important; }
    }

    .clex-search-bar-wrapper-side{
        padding: 8px !important;
    }
    .clex-search-bar-wrapper-top,
    .clex-search-bar-wrapper-side{
        border: 0px !important;
        border-radius: 0px 30px 30px 0px !important;
        box-shadow: none !important;
    }
    .clear-search{
        align-items: center;
        background-color: #fff;
        border-radius: 0px 30px 30px 0px;
        color: #5e5e5e;
        cursor: pointer;
        display: flex;
        padding: .5rem;
        &:active{
            color: #242424;
        }
    }
}