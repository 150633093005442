.info-sidebar-css { // Ls request sidebar
  height: 100%;
  .ls-sidebar-fields {
    height: calc(100% - 133px);
  }
}
.info-dup-check-css { // Right side of duplicate checker
  .sidebar-header {
    background: #5b7fca;
  }
}
.info-select-css { // Left side of duplicate checker
  background-color: #fff;
  box-shadow: 5px 0px 16px -1px rgba(0,0,0,.2);
  height: 100%;
  .sidebar-header {
    background: #3056a6;
    height: 70px;
    margin-top: -1px;
  }
  .ls-sidebar-fields {
    color: #4b4b4b;
    height: calc(100% - 70px);
  }
}
.sidebar-fixed {
  position: sticky;
  text-align: center;
  top: 0;
  .sidebar-header {
    align-items: center;
    background: #2f56a6;
    color: white;
    padding: 15px;
    .sidebar-header-info{
      .heading{
        font-size: 1rem;
        margin-bottom: 0.2rem;
      }
      .sidebar-header-subtext {
        display: flex;
        font-size: 0.65rem;
        flex-direction: row;
        justify-content: center;
        .datetime {
          margin-left: 10px;
        }
      }
    }
    .info-icon {
      cursor: pointer;
      min-width: 12px;
      position: absolute;
      top: 35%;
      left: 0;
      .dup-check-back{
        color: #fff;
        margin-right: 0px;
        &:hover{
          background-color: #dadada3b;
          color: #fff;
        }
      }
      .float-left {
        color: #fff;
        float: right;
        font-size: 1rem;
      }
    }
    .close-sidebar{
      left: 10px;
      position: absolute;
      top: 10px;
    }
  }
  .sidebar-fixed-fields {
    align-items: center !important;
    background-color: #d8e5ff;
    display: flex;
    font-size: 12px;
    min-height: 80px;
    &.dup-fixed-fields {
      min-height: 60px !important;
      div > div:nth-child(2) {
        font-size: 0.75rem;
      }
      .field-box {
        padding: 10px;
        width: 50%;
      }
    }
  }
}
.ls-sidebar-fields {
  max-width: 500px;
  overflow: auto;
  padding: 22px 22px 0px;
  .fieldset-styles{
    border-color: #d8e5ff;
    border-image: initial;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    display: block;
    margin-bottom: 20px;
    margin-inline-end: 2px;
    margin-inline-start: 2px;
    min-inline-size: min-content;
    padding-block-end: 0.625em;
    padding-block-start: 0.35em;
    padding-inline-end: 0.75em;
    padding-inline-start: 0.75em;
    &:hover{
      background-color: #f0f4fb;
    }
    &.duplicate-check{
      border-color: #f1f1f1;
      &:hover{
        background-color: #f1f1f1;
      }
    }
    .fieldset-legend-styles{
      background-color: #fff;
      border-radius: 4px;
      float: none;
      font-size: 0.8rem;
      font-weight: 300;
      line-height: inherit;
      margin-bottom: 0;
      padding: 0px 4px;
      white-space: normal;
      width: auto;
    }
    .fieldset-content-styles{
      font-size: .8rem;
      font-weight: 500;
      .pre {
        color: inherit;
        font-size: inherit;
        margin: 0px;
        overflow-y: auto;
        white-space: pre-wrap;
        word-break: break-word;
      }
    }
  }
}
.action-button-container{
    position: absolute !important;
    right: 10px;
    top: 18px;
  button.column-menu {
    border: none;
    box-shadow: none;
    color: #C4C4C4; //grey
    cursor: pointer;
    display: inline-block;
    outline: none;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    &:hover, &:focus{
      background-color:#d8e5ff !important;
      color: black;
    }
  }
  
  .dropdown-menu-adjusted {
    font-size: .825rem !important;
    li{
      button{ 
        background-color: transparent;
        border: 0;
        clear: both;
        color: #495057;
        cursor: pointer;
        display: block;
        font-weight: 400;
        padding: 0.45rem 1.2rem;
        text-align: inherit;
        white-space: nowrap;
        width: 100%;
        &:hover{
          background-color: #eeeeee;
        }
        &:active{
          background-color: #2a3142;
          color: white;
        }
      }
    }  
  }
}


@media screen and (max-width: 750px) {
  .info-select-css {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
