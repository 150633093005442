// ----- START Login.js, PasswordForm.js, and PasswordReset.js STYLES ----- //
.input-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 440px;
    width: 100%;
    h1{
        color: #5850ec;
        font-size: 2rem;
        font-weight: 900;
        margin-bottom: 25px;
    }
    .override-cancel-button{
        color: #2F56A6;
        font-size: 14px;
        padding: 0px;
        text-decoration: none;
    }
    .login-button{
        margin: 10px 0px;
        height: 40px;
        color: #fff;
        font-size: .9rem;
        &:disabled{
            background-color: #6E81A9;
            &:hover{
                cursor: not-allowed;
            }
        }
    }  
}
#password-constraints{
    font-size: .85em;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    li{
        &:nth-child(1):before,
        &:nth-child(2):before,
        &:nth-child(3):before {
          content: '\2022'; 
          padding-right: 5px;
        }
        &.pass:before{
            color: #155724;
            content: '\2713';
            padding-right: 5px;
        }
        &.fail:before{
            color: #721c24;
            content: '\2717';
            padding-right: 5px;
        }
        &.standard:before{
            color: #000;
            content: '\2022';
            padding-right: 5px;
        }
    }
}
// ----- END Login.js, PasswordForm.js, AND PasswordReset.js STYLES ----- //

// ----- START AgencyView.js AND Login.js STYLES ----- //
.login-logo{
    max-width: 60%;
    width: 140px;
    position: fixed;
    top: 20px;
    right: 20px;
}
@media screen and (max-width: 767px){
    .login-logo{
        left: 20px;
        right: 0px;
    }
}
// ----- END AgencyView.js AND Login.js STYLES ----- //

// ----- START Login.js AND PasswordForm.js STYLES ----- //
.login-container-initial{
    display: flex;
    justify-content: center;
    align-items: center;
}
// ----- END Login.js AND PasswordForm.js STYLES ----- //

// ----- START Login.js STYLES ----- //
.pageBody{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    .slider-info{
        align-items: center;
        background-image: linear-gradient(180deg, #5850EC, #ADA9FF);
        max-width: 550px !important;
        .carousel-wrapper{
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            text-align: center;
            justify-content: center;
            align-items: center;
        }
    }
    .input-wrapper{
        .remember-container{
            margin: 20px 0px 0px 0px;
        }
        .account-activate{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }
        .new-user-link{
            font-size: 14px !important;
        }
    }
    .button-eye {
        position: absolute;
        right: 10px;
        bottom: 50%;
        transform: translate(0, 50%);
    }
    .login-footer {
        bottom: 10px;
        position: absolute;
        .login-footer-title{
            color: #D05D4E !important;
            font-size: 0.75rem;
            margin-bottom: auto;
            text-align: center;
        }
        .login-footer-sub-title{
            font-size: .75rem;
            text-align: center;
        }
    } 
}
// ----- END Login.js STYLES ----- //

// ----- START LoginCarousel.js STYLES ----- //
.ImgCont{
    text-align: center;
    margin-bottom: 150px;
    img{
        width: 60%;
    }
}
.TextCont{
    line-height: 1.3em;
    font-size: .85rem;
    color: black;
}
// ----- END LoginCarousel.js STYLES ----- //