@mixin report-status($color) {
    position: absolute;
    right: 12px;
    color: $color;
    font-size: 1.7rem;
}

:root{
    // Report Tracking Filters
    --report-tracking-completed: #495057;
    --report-tracking-past-due: #d05d4e;
    --rreport-tracking-received: #1e7e34;
    --report-tracking-upcoming: #AF9308;

    // Proc Request Statuses
    --filter-pending: #5a70ac;
    --filter-approved: #009688;
    --filter-progress: #ff9800;
    --filter-completed: #636d77;
    --filter-delivered: #2196f3;
    --filter-withdrawn: #f44336;
  }



.due-reports-list-box {
    align-items: center;
    background-color: #E4EAF6;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    margin: 1.5rem 0;
    min-height: 116px;
    padding: 1.1rem;
    position: relative;
    transition: all 0.3s;
    &:hover, &:focus {
        background-color: #d5dded;
    }
    &:first-child{
        margin-top: 0;
    }
    &:last-child{
        margin-bottom: 0;
    }
}

.due-reports-list-idx {
    width: 10%;
    span{
        margin-left: 40%;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        opacity: 0.8;
    }
}

.report-sidelabel-container{
    height: 90px;
    width: 28px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    color: white;
    .report-sidelabel-text{
        font-size: .9rem;
        text-transform: uppercase;
        font-size: .7rem;
        font-weight: 600;
        text-align: center;
        margin: 0px;
        writing-mode: vertical-rl;
        transform: rotate(-180deg);
    }

    &.completed{
        background-color: #495057;
    }
    &.past-due{
        background-color: var(--report-tracking-past-due);
    }
    &.received{
        background-color: #1e7e34;
    }
    &.upcoming{
        background-color: #AF9308;
    }
}

.due-reports-list-content {
    width: 90%;
    margin-left: 5%;
}

.due-reports-list-title {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 15px;
    opacity: 0.8;
    line-height: 1.1rem;
}

.due-reports-list-subtitle {
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.40);
    font-weight: 500;
    font-size: 11px;
}

.report-past-due {
    @include report-status(#d05d4e);
   
}
.report-completed {
    @include report-status(#28a745);
}

.email-date {
    padding-left: 5em;
    font-size: 0.75em;
}