.no-valid-result-message {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
}

.popup-detail-title h3 {
  font-size: 25px;
  font-weight: 300;
  text-align: left;
}

.proclamation-request-reason {
  color: #acacac;
  margin-bottom: 10px;
}

.search-filter-wrapper {
  position: absolute;
  top: 0;
  width: calc(100% - 6px);
  padding: 20px 11px 0 20px;
  z-index: 4;
  background-color: #f1f5ff;
  .user-guide {
    background-color: #566fb1;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    position: absolute;
    right: 0;
    top: 0;
    &:hover,
    &:focus {
      background-color: #3f507f;
      color: #fff;
    }
  }
}
.proc-request-list-container-tasks {
  height: calc(100vh - 94px);
  overflow: auto;
  padding: 130px 20px 0;
  position: relative;
  .proc-block:first-child {
    margin-top: 30px;
  }
  .no-results-message {
    font-size: 1.2rem;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}
@media screen and (max-width: 854px) {
  .proc-request-list-container-tasks {
    height: calc(100vh - 489px);
  }
}
.proc-list-container {
  position: relative;
  min-width: 400px;
}

@media screen and (max-width: 854px) {
  .proc-list-container {
    flex: 0 0 auto !important;
  }
}

.proclamation-request-icon-text {
  margin-right: 8px;
}

.proclamation-request-icon-right {
  margin-right: 4px;
}

.proclamation-request-table-UI {
  margin: 0px 16px;
}

.proclamation-request-select {
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  -webkit-appearance: none;
}
.search-box {
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 50px;
  overflow: hidden;
  input,
  select {
    border: none;
    font-size: 1.1rem;
    height: 100%;
    outline: none;
    padding: 0px;
    width: 100%;
    box-sizing: border-box;
    &:focus {
      box-shadow: none !important;
    }
  }
  .search-by {
    background-color: #5770b1;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    color: white;
    height: 55px;
    padding-left: 20px;
    padding-right: 20px;
    width: 220px;
    div {
      font-size: 9px;
      height: auto;
      padding-top: 2px;
    }
    select {
      background-image: url('../../../public/img/down-arrow-white.png');
      background-color: #5770b1;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 0px;
      color: white;
      cursor: pointer;
      width: 100%;
    }
  }
  .search-loading {
    div {
      height: 25px;
      width: 25px;
    }
  }
  > select {
    height: 48px;
  }
  .search-button {
    color: #707070;
    cursor: pointer;
    margin: 0 0.5rem 0 0.8rem;
    &:hover {
      color: #96b3fb;
    }
  }
  &:focus-within {
    outline: 2px solid #5770b1;
  }
}
.filter-label {
  font-size: 0.8rem;
}
.dropdown-toggle {
  color: #566fb1 !important;
  font-weight: 600 !important;

  &:hover,
  &:focus {
    color: #485c94 !important;
    box-shadow: none !important;
  }
  &:global(.dropdown-toggle) {
    padding: 5px;
    font-size: 0.8rem;
    &::after {
      display: none;
    }
  }
}

.dropdown-menu {
  &:global(.dropdown-menu) {
    font-size: 0.825rem !important;
    top: -3px !important;
  }
  &.filter {
    &:global(.dropdown-menu) {
      width: 190px;
    }
  }
  .status-dropdown-item {
    &:global(.dropdown-item) {
      background-color: transparent;
      border: 0;
      clear: both;
      color: #495057;
      cursor: pointer;
      display: block;
      font-weight: 400;
      padding: 0.3rem 1.5rem;
      text-align: inherit;
      white-space: nowrap;
      width: 100%;
      &:active {
        background-color: #2a3142;
        color: white;
      }
      &:hover {
        color: #1e2125;
        background-color: #e9ecef;
      }
    }
    .dropdown-checkmark {
      margin-top: -3px;
      position: absolute;
      right: 3px;
    }
  }
}

.create-proclamation-request-button {
  background: #5770b1 !important;
  border: 0px !important;
  border-radius: 200px !important;
  bottom: 20px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: white;
  height: 75px;
  position: absolute;
  right: 20px;
  transition: all 0.4s;
  width: 75px;
  z-index: 10;
  .new-button-text {
    display: none;
  }
  &:hover {
    background-color: #3e5182 !important;
    width: auto;
    padding-right: 15px;
    .new-button-text {
      display: inline;
    }
  }
  &:active {
    background-color: #324169 !important;
  }
}

#popover-checkbox-toggle {
  background-color: transparent !important;
  border: none;
  color: #566fb1 !important;
  font-size: 0.9rem;
  padding: 6px;
  &:global(.active) {
    color: #35446d !important;
    outline: none !important;
  }
}

.proclamation-request-filter-container {
  color: #838383;
  cursor: pointer;
  display: block;
  font-size: 0.8rem;
  padding-left: 30px;
  position: relative;
  text-align: left;
  user-select: none;
}

/* Hide the browser's default checkbox */
.proclamation-request-filter-container input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

/* Create a custom checkbox */
.proclamation-request-filter-checkmark {
  background-color: #f4f8ff;
  border-radius: 1px;
  box-shadow: inset 0px 0px 3px 1px rgba(68, 68, 68, 0.25);
  height: 16px;
  left: 5px;
  position: absolute;
  top: 3px;
  width: 16px;
}

/* On mouse-over, add a grey background color */
.proclamation-request-filter-container:hover
  input
  ~ .proclamation-request-filter-checkmark {
  background-color: #566fb1;
  transition-duration: 0.4s;
}

/* When the checkbox is checked, add a blue background */
.proclamation-request-filter-container
  input:checked
  ~ .proclamation-request-filter-checkmark {
  background-color: #566fb1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.proclamation-request-filter-checkmark:after {
  content: '';
  display: none;
  position: absolute;
}

/* Show the checkmark when checked */
.proclamation-request-filter-container
  input:checked
  ~ .proclamation-request-filter-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.proclamation-request-filter-container
  .proclamation-request-filter-checkmark:after {
  border: solid white;
  border-width: 0px 2px 2px 0;
  height: 9px;
  left: 6px;
  top: 2px;
  transform: rotate(45deg);
  width: 5px;
}

.apply-button-filter {
  background-color: #355494 !important;
  border: none;
  border-radius: 4px;
  color: #fff !important;
  margin-top: 18px;
  padding: 5px 10px;
  width: 80% !important;
  &:hover {
    background-color: #002164 !important;
    transition-duration: 0.4s;
  }
}

.proclamation-request-sidelabel-container {
  border-radius: 4px;
  color: white;
  display: flex;
  height: 90px;
  justify-content: center;
  margin-right: 30px;
  width: 28px;
  .proclamation-request-sidelabel-text {
    font-size: 0.7rem;
    font-weight: 600;
    margin: 0px;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
  }
  &.completed {
    background-color: #5750ec;
  }
  &.past-due {
    background-color: #d05d4e;
  }
  &.received {
    background-color: #1e7e34;
  }
  &.upcoming {
    background-color: #495057;
  }
}
.proclamation-request-list-card-mobile {
  display: flex;
}
.proc-date-needed {
  position: absolute;
  top: 6px;
  right: 6px;
}

.proclamation-request-sort-background {
  font-size: 12px;
  font-weight: 500;
  margin-right: 20px;
  svg {
    color: #566fb1;
  }
  &:last-child {
    margin-right: 10px;
  }
}
.sort-input {
  background-color: transparent;
  border: none;
  color: #566fb1;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  &:hover {
    color: #485c94;
  }
}
.sort-direction {
  color: #566fb1;
  cursor: pointer;
  padding-left: 5px;
}
.clear-all {
  color: #6e6e6e;
  cursor: pointer;
  padding-left: 10px;
}

.proclamation-request-committee {
  background: #aa6600;
  border-radius: 15px;
  color: white;
  margin: 3px;
  padding: 3px 6px;
  text-align: center;
  width: max-content;
}

.proc-block {
  margin: 16px;
  margin-left: auto;
  margin-right: auto;
  .probo {
    font-size: 0.8rem;
    white-space: nowrap;
    .cm {
      font-size: 0.8rem;
    }
  }
  .edit-proclamation-button {
    border-bottom-left-radius: 0.5rem;
    border: none;
    color: #002164;
    width: 25px;
    position: absolute;
    padding: 0rem;
    right: 0;
    top: 0;
    &:hover {
      background-color: #e4e4e4;
    }
    &:active {
      background-color: #d2d2d2;
    }
  }

  .proc-reason-text {
    font-size: 0.8rem;
  }

  .proclamation-request-first-block {
    display: flex;
    width: 35%;
    .field-name {
      font-size: 12px;
    }
  }
  .proclamation-request-middle-block {
    border-right: 1px solid #2f56a640;
    margin-right: 30px;
    padding-right: 30px;
    width: 35%;
    .proclamation-field-name {
      font-weight: 600;
    }
    .proclamation-request-local-law {
      color: #acacac;
      font-weight: 100;
    }
  }
  .proclamation-request-end-block {
    width: 30%;
    .proclamation-request-date-padding {
      padding-bottom: 10px;
    }
    .proclamation-request-status {
      align-items: baseline;
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}

.proclamation-request-right {
  float: right;
}

.proclamation-request-block:hover {
  background-color: #5770b1;
  .proclamation-request-local-law,
  .proclamation-request-honoree-title,
  .proclamation-request-first-block,
  .proclamation-request-middle-block,
  .proclamation-request-end-block {
    color: white;
  }
  .proclamation-request-middle-block {
    border-right: 1px solid #fff;
  }
}

@media screen and (max-width: 400px) {
  .proclamation-request-search-menu,
  .proclamation-request-block {
    flex-direction: row !important;
  }

  .field-name-mobile {
    font-weight: 800;
    padding-right: 45px;
    width: 50%;
  }

  .mobile-date {
    font-size: 10px;
    span {
      margin-left: 5px;
      font-weight: 800;
    }
  }

  .proclamation-request-block:hover {
    color: white;
    .proclamation-request-honoree-title-mobile {
      color: white;
    }
  }

  .proclamation-request-honoree-field-mobile {
    padding: 0px 12px;
    width: 72%;
  }

  .proclamation-request-table-UI {
    display: flex;
    margin-left: 16px;
  }

  .proclamation-request-block {
    flex-direction: column;
    height: 100%;
    max-height: 172px;
    overflow: auto;
    padding: 18px;
    div {
      display: flex;
      flex-direction: row;
    }
  }

  .search-box {
    width: 100%;
    .search-by {
      width: 180px;
    }
  }

  .proclamation-request-sort {
    margin-right: 0px;
  }
}

@media screen and (max-width: 576px) {
  .proclamation-request-sort .proclamation-request-calendar-button {
    width: 33px;
  }
}

.popover-container {
  border-radius: 6px !important;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 205px !important;
}

.popover-outer-container {
  :global(.arrow::before) {
    border-width: 0px !important;
  }
  > div {
    border: none !important;
  }
}

.filter-popover-user-guide {
  color: #b2b2b2;
  position: absolute;
  right: 0px;
  top: 5px;
}

.proclamation-contact-person {
  align-items: center;
  display: inline-flex;
  gap: 0.2rem;
  text-decoration: 'none';
}
