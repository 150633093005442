.reports-search-container{
    background-color: #f1f5ff;
    padding: 30px 20px 0px;
    position: sticky;
    top: 0px;
    z-index: 1;
    .report-search {
        background-color: #FFF;
        border-radius: 30px;
        box-shadow: 0 1px 5px 0 rgba(0,0,0, 0.12);
        display: flex;
        height: 55px;
        margin-inline: auto;
        max-width: 960px;
        input, select {
            border: none;
            font-size: 14px;
            padding: 0px;
            &:focus-visible{
                outline: none;
            }
        }
        .search-by {
            background-color: #5770B1;
            border-bottom-left-radius: 30px;
            border-top-left-radius: 30px;
            color: white;
            height: 55px;
            padding-left: 20px;
            width: 220px;
        }
        .search-box {
            align-items: center;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            display: flex;
            height: 100%;
            width: 100%;
            &:focus-within{
                border: 1px solid #5770b1;
            }
            input, select{
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                color: #828282;
                height: 100%;
                &:focus{
                    box-shadow: none;
                }
            }
            .search-loading {
                div {
                    height: 25px;
                    margin-right: 10px;
                    width: 25px;
                }
            }
            .search-button{
                color: #828282;
                cursor: pointer;
                margin: 0 12px;
                &:hover { color: #96B3FB; }
            }
            select{
                cursor: pointer;
            }
        }
    }
    .tracker-description {
      font-size: 0.70rem;
      margin-right: 20px;
    }
    .report-sort-and-filter-container {
        align-items: center;
        display: flex;
        height: 58px;
        justify-content: space-between;
        .sort-filter-flex{
          display: flex;
          align-items: center;
        }
        // margin-inline: auto;
        // max-width: 960px;
        .sort-direction {
            color: #566fb1;
            cursor: pointer;
            padding-left: 5px;
        }
        .filter-label{
            font-size: 0.8rem;
          }
        .dropdown-toggle{
        color: #566fb1 !important;
        font-weight: 600 !important;
        
        &:hover, &:focus{
            color: #485c94 !important;
            box-shadow: none !important;
        }
        &:global(.dropdown-toggle){
            padding: 5px;
            font-size: .8rem;
            &::after {
            display: none;
            }
        }
        }
    }
    .report-search, .report-sort-and-filter-container{
        .report-select {
            border: 0;
            background-color: transparent;
            color: #FFF;
            width: 100%;
            text-align: left;
            padding: 0 0 0 0.55em;
        }
    }
}
.search-dropdown-menu{
    &:global(.dropdown-menu){
      color: #6c757d !important;
      font-size: .825rem !important;
      margin: 0 !important;
      padding: .5rem 0 !important;
    }
}
.input-text-area {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  display: block;
  font-size: 0.9rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  width: 100% !important;
}
.date-padding {
  padding: 5px 15px;
}
.search-dropdown-menu-item{
    &:global(.dropdown-item){
      background-color: transparent;
      border: 0;
      clear: both;
      color: #495057;
      cursor: pointer;
      display: block;
      font-weight: 400;
      padding: .35rem 1.5rem;
      text-align: inherit;
      white-space: nowrap;
      width: 100%;
      &:active{
        background-color: #2a3142;
        color: white;
      }
    }   
  }
.report-list-container {
    background-color: #f1f5ff;
    padding-inline: 20px;
    .report-block {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 1px 5px 0 rgba(0,0,0,.12);
        color: #828282;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-size: 12px;
        height: 100%;
        margin: 16px;
        max-height: 120px;
        min-height: 95px;
        overflow: hidden;
        padding: 15px 10px;
        position: relative;
        &:hover {
            background-color: #5770B1;
            color: white; 
            .report-middle-block {
                border-right: 1px solid #fff;
            }
        }
        .report-first-block {
            display: flex;
            min-width: 110px;
            text-align: center;
            width: 25%;
            .report-last-date {
                font-size: 17px;
                font-weight: 600;
                line-height: 21px;
                
                .report-last-date-year {
                    font-size: 17px;
                    font-weight: 200;
                }
            }
        }
        .report-sidelabel-container{
        /* Originally under .report-first-block, but separating so that the sidelabel is visible in mobile view */
            border-radius: 4px;
            color: white;
            display: flex;
            height: 90px;
            justify-content: center;
            margin-right: 20px;
            width: 28px;
            &.completed{ background-color: #495057; }
            &.past-due{ background-color: #d05d4e; }
            &.received{ background-color: #1e7e34;; }
            &.upcoming{ background-color: #AF9308 }
            &.type{ background-color: #495057; }
            .report-sidelabel-text{
                font-size: .9rem;
                font-weight: 600;
                margin: 0px;
                text-align: center;
                text-transform: uppercase;
                transform: rotate(-180deg);
                writing-mode: vertical-rl;
            }
        }
        .report-middle-block {
            border-right: 1px solid #2f56a640;
            margin-right: 30px;
            padding-right: 25px;
            width: 47%;
            .report-title {
                margin-bottom: 10px;
            }
        }
        .report-first-block,
        .report-middle-block{
            .report-sub-title { 
                color: #ACACAC;
                font-size: 12px;
                margin-bottom: 10px;
            }
        }
        .report-end-block {
            padding-right: 10px;
            width: 30%;
            .report-date-padding { padding-bottom: 10px; }
            .report-committees {
                align-items: baseline;
                display: inline-flex;
                flex-wrap: wrap;
                width: 100%;
                .report-committee {
                    background-color: #AA6600;
                    border-radius: 15px;
                    color: white;
                    margin: 3px;
                    padding: 3px 6px;
                    text-align: center;
                    width: max-content;
                }
            }
        }
        &:first-child{
            margin-top: 0;
        }
        .icon{
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
        }
    }
}
.no-valid-result-container{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 197px);
    justify-content: center;
    p{
      font-size: 18px;
      margin-bottom: 0;
      text-align: center;
    }
    img{
      margin-bottom: 1rem;
      max-width: 430px;
    }
  }

.dropdown-menu{
    &:global(.dropdown-menu){
      font-size: .825rem !important;
      top: -3px !important;
    }
    &.filter{
      &:global(.dropdown-menu){
        width: 190px;
      }
    }
    .status-dropdown-item{
      &:global(.dropdown-item){
        background-color: transparent;
        border: 0;
        clear: both;
        color: #495057;
        cursor: pointer;
        display: block;
        font-weight: 400;
        padding: .3rem 1.5rem;
        text-align: inherit;
        white-space: nowrap;
        width: 100%;
        &:active{
          background-color: #2a3142;
          color: white;
        }
        &:hover{
          color: #1e2125;
          background-color: #e9ecef;
        }
      } 
      .dropdown-checkmark{
        margin-top: -3px;
        position: absolute;
        right: 3px;
      }
    }
  }
.popover-outer-container{
    > div { 
      border: none !important;
      min-width: 400px;
    }
    .popover-container {
      border-radius: 6px !important;
      box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.29);
      overflow: hidden;
      width: 400px !important;
      .list-filter-dropdown-wrapper{
        &:global(.popover-body){
          padding: 8px 0px;
        }
        .filter-flexbox {
          align-items: center;
          display: flex;
          > div {
            padding: 0px 5px;
          }
          div {
            width: 400px;
            border-right: black 1px solid !important;
          }
        }
        .color-filter-container {
          cursor: pointer;
          display: block;
          font-size: .9rem;
          position: relative;
          padding: 7px 0px 2px 54px;
          user-select: none;
          &.past-due{ color:  #d05d4e; }
          &.received{ color:  #1e7e34; }
          &.completed{ color: #495057; }
          &.upcoming{ color: #AF9308; }
          &.past-due:hover input ~ .color-filter-checkmark,
          &.past-due input:checked ~ .color-filter-checkmark.past-due {
            background-color:  #d05d4e;
          }
          &.received:hover input ~ .color-filter-checkmark,
          &.received input:checked ~ .color-filter-checkmark.received {
            background-color: #1e7e34;
          }
          &.completed:hover input ~ .color-filter-checkmark,
          &.completed input:checked ~ .color-filter-checkmark.completed {
            background-color: #495057;
          }
          &.upcoming:hover input ~ .color-filter-checkmark,
          &.upcoming input:checked ~ .color-filter-checkmark.upcoming {
            background-color: #AF9308;
          }
          input{
            cursor: pointer;
            height: 0;
            position: absolute;
            opacity: 0;
            width: 0;
            &:checked ~ .color-filter-checkmark:after {
              display: block;
            }
          }
          .color-filter-checkmark {
            border-radius: 5px;
            height: 20px;
            left: 18px;
            position: absolute;
            top: 9px;
            width: 20px;
            &.past-due{ border: 2px solid  #d05d4e; }
            &.received{ border: 2px solid #1e7e34; }
            &.completed{ border: 2px solid #495057; }
            &.upcoming{ border: 2px solid #AF9308; }
            &:after {
              border: solid white;
              border-width: 0 3px 3px 0;
              content: "";
              display: none;
              height: 10px;
              left: 6px;
              position: absolute;
              top: 3px;
              transform: rotate(45deg);
              width: 5px;
            }
          }
          .filter-popover-user-guide{
            color: #838383;
            position: absolute;
            right: 8px;
            top: 10px;
          }
        }
        .status-icons{
          padding-right: 10px;
        }
      }
      .apply-button-filter{
        background-color: #355494 !important; 
        border: none;
        border-radius: 4px;
        color: #fff !important;
        margin: 15px auto 0;
        padding: 5px 10px;
        width: 30% !important;
        display: block;
        &:hover{
            background-color: #002164 !important;
            transition-duration: 0.4s;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .report-search {
        width: 100%;
        .search-by { width: 180px; }
    }
    .report-block {
        flex-direction: row !important;
        max-height: 172px !important;
        overflow: auto !important;
        padding:18px !important;
        &:hover { color: white; }
        div {
            display: flex;
            flex-direction: row;
        }
        div:last-child {
            div:first-child { padding-right: 54px; }
        }
        .report-sub-title-mobile {
            font-weight: bold;
            width: 25%;
        }
        .report-sub-field-mobile,
        .report-sub-title-mobile-committee  {
            padding: 0px 12px;
            width: 72%;
        }
        .report-sub-title-mobile-committee { line-height:  2.5; }
        .mobile-date {
            font-size: 10px;
            span {
                font-weight: 800;
                margin-left: 5px;
            }
        }
    }    
}