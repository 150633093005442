.no-valid-result-container {
  left: 50%;
  position: sticky;
  top: 40%;
  transform: translate(-50%, -40%);
  max-width: 320px;
}

/*---------- For TableHeader.js ----------*/
.table-column-tooltip {
  border-bottom: 1px dotted #fff;
  &:hover {
    cursor: help;
  }
}

/*---------- For LsRequestsTable.js ----------*/
.spinner-animation {
  color: #4540a2;
  height: 6em !important;
  margin-left: 50%;
  width: 6em !important;
  z-index: 999;
  position: absolute;
  margin: auto;
  top: 100px;
  bottom: 0;
  left: 230px;
  right: 0;
}
.table-container {
  border-radius: 4px;
  height: calc(100vh - 181px);
  margin: auto;
  overflow: auto;
  width: 96%;
  &.status {
    height: calc(100vh - 236px);
    width: 99%;
  }
  .list-table {
    border-collapse: separate;
    border-spacing: 0 6px;
    margin-top: -6px;

    th {
      background-color: #4a5f96;
      border-right: 1px solid white;
      font-size: 10px;
      font-weight: bold;
      line-height: 14px;
      position: sticky;
      text-align: center;
      top: 0px;
      width: 90px;
      z-index: 3;
      &:not(.resizer) {
        padding: 10px 18px !important;
      }
      .alignSortUp,
      .alignSortDown {
        vertical-align: middle;
      }
      .alignSortDown {
        transform: translateY(-25%);
      }
      .alignSortUp {
        transform: translateY(20%);
      }
      &:first-child,
      &:last-child,
      .checkbox-header {
        color: white;
        width: 80px !important;
        z-index: 4;
      }

      &:first-child,
      .checkbox-header {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
      &:last-of-type {
        border-right: none;
      }
    }
    .status-header {
      width: 80px !important;
      z-index: 3;
      left: 90px;
      position: sticky;
      width: 80px !important;
      max-width: 200px;
      min-width: 200px;
      td.checkbox-header {
        color: rgb(255, 19, 19);
        width: 80px !important;
        z-index: 2;
      }
    }

    .new-status-header {
      width: 80px !important;
      z-index: 3;
      left: 180px;
      position: sticky;
      width: 80px !important;
      td.checkbox-header {
        color: rgb(255, 19, 19);
        width: 80px !important;
        z-index: 2;
      }
    }

    .new-status-requestor-header {
      width: 80px !important;
      z-index: 3;
      left: 288px;
      position: sticky;
      width: 80px !important;
      overflow: hidden;
      td.checkbox-header {
        color: rgb(255, 19, 19);
        width: 80px !important;
        z-index: 2;
      }
    }

    tbody {
      border: 1px solid #ccc;
      tr.archived-ls td {
        background-color: darkgrey !important;
        cursor: default;
      }
      tr td:first-child,
      tr td:last-child {
        padding: 0px 15px;
        position: sticky;
        z-index: 2;
      }
      tr td:first-child {
        border-left: 1px solid rgba(189, 189, 189, 0.38);
        left: 0;
      }

      tr td:last-child {
        border-right: 1px solid rgba(189, 189, 189, 0.38);
        right: -1px;
        cursor: default;
      }

      tr td.status {
        // overflow: visible;
        border-left: 1px solid rgba(189, 189, 189, 0.38);
        position: sticky;
        left: 90px;
      }

      tr td.new-status {
        // overflow: visible;
        border-left: 1px solid rgba(189, 189, 189, 0.38);
        position: sticky;
        left: 180px;
        min-width: 150px;
      }

      tr td.new-status-requestor {
        // overflow: visible;
        border-left: 1px solid rgba(189, 189, 189, 0.38);
        position: sticky;
        left: 288px;
        min-width: 200px;
      }

      tr:nth-of-type(odd) {
        td:first-of-type,
        td:last-of-type,
        button.column-menu {
          background-color: #fff;
          overflow: visible;
        }
      }
      tr:nth-of-type(even) {
        td:first-of-type,
        td:last-of-type,
        button.column-menu {
          background-color: #f2f2f2;
          overflow: visible;
        }
      }

      tr:nth-of-type(odd) td.new-status,
      tr:nth-of-type(odd) td.status,
      tr:nth-of-type(odd) td.new-status-requestor {
        background-color: #fff;
      }

      tr:nth-of-type(even) td.new-status,
      tr:nth-of-type(even) td.status,
      tr:nth-of-type(even) td.new-status-requestor {
        background-color: #f2f2f2;
      }

      td:not(.resizer) {
        border: none;
        border-bottom: 1px solid #ccc;
        border-top: 1px solid rgba(189, 189, 189, 0.38);
        height: 60px;
        padding-left: 10px !important;
        padding-right: 10px !important;
        text-align: center;
        width: 90px;
        &.ls-attachment-descriptions div,
        &.ls-background div,
        &.ls-background-existing-laws div,
        &.ls-background-inspiration div,
        &.ls-cm-notes div,
        &.ls-confidential-comments div,
        &.ls-council-member-attachment div,
        &.ls-description-legislative-solution div,
        &.ls-description-problem div,
        &.ls-description-related-discussions div {
          align-content: center;
          display: block;
          height: 100px;
          // justify-content: safe center; Uncomment and replace current justify-content when "safe" becomes supported on Chrome
          overflow: auto;
          padding-right: 5px;
          text-align: left;
          white-space: pre-wrap;
          word-break: break-word;
        }
        &.ls-duplicates div {
          cursor: help;
          text-decoration: underline;
          text-decoration-color: #757575;
          text-decoration-style: dashed;
        }
      }
      td,
      td pre {
        color: black;
        font-size: 11px;
        // font-weight: bold;
        line-height: 15px;
        position: relative;
      }
    }
  }

  .checkbox {
    -webkit-transform: scale(1.2);
  }

  .array-list {
    padding: 5px;
  }

  .is-hidden {
    border-radius: 5px;
    bottom: 5px;
    color: #4a5f96;
    opacity: 0;
    padding: .25rem;
    position: absolute;
    right: 5px;
    transition: opacity 0.3s ease-in-out;
  }

  .is-hidden:hover {
    background: #637cbb93;
    color:var(--bs-body-bg);
  }

  .td-relative {
    font-size: larger;
    position: relative;
    z-index: 1;
  }

  td:hover .is-hidden {
    opacity: 1;
  }

  .ls-request-edit-img {
    cursor: pointer;
    margin-left: 5%;
    visibility: hidden;
  }
  
  .ls-request-delete-img {
    margin-left: 5%;
    visibility: hidden;
    cursor: pointer;
  }

  .ls-request-tr:hover .ls-request-edit-img {
    visibility: visible;
  }

  .ls-request-tr:hover .ls-request-delete-img {
    visibility: visible;
  }

  .dropdown-container {
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    position: absolute;
    z-index: 100;
    border-radius: 3%;
    min-width: 200px;
  }

  .dropdown-container td {
    border: none;
  }

  button.column-menu {
    border: none;
    box-shadow: none;
    color: #c4c4c4; //grey
    cursor: pointer;
    display: inline-block;
    padding: 5px 5px;
    outline: none;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    &:hover,
    &:focus {
      background-color: #e4e4e4 !important;
      color: black;
    }
  }

  .dropdown-menu-adjusted {
    font-size: 0.825rem !important;
    li {
      button {
        background-color: transparent;
        border: 0;
        clear: both;
        color: #495057;
        cursor: pointer;
        display: block;
        font-weight: 400;
        padding: 0.45rem 1.2rem;
        text-align: inherit;
        white-space: nowrap;
        width: 100%;
        &:hover {
          background-color: #eeeeee;
        }
        &:active {
          background-color: #2a3142;
          color: white;
        }
      }
    }
  }
}
.modal-background {
  background-color:rgba(0, 0, 0, 0.4);
  height: 100vh;
  position:absolute;
  width: 100vw;
  z-index: 98;
}
.modal-expansion {
  background-color: white;
  border: 8px solid #293042;
  display: flex;
  flex-direction: column;
  font-size: large;
  height: fit-content;
  justify-content: space-between;
  left: 25vw;
  letter-spacing: 0.5px;
  line-height: 1.6;
  margin: auto;
  max-height: 60vh;
  overflow: auto;
  padding: 16px;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  width: 70vw;
  word-spacing: 2px;
  z-index: 99;
  .modal-title {
    text-align:center;
    border-bottom: 2px #2a3142 solid;
    font-weight: bold;
  }

  .modal-value {
    padding-top: 2rem;
    text-align: center;
  }
}

.resizer {
  background-color: transparent !important;
  border: none !important;
  max-width: 3px !important;
  min-width: 3px !important;
  padding: 0 0 0 0 !important;
  width: 3px !important;
  &:hover,
  &:active {
    background-color: #f1f5ff !important;
  }
}
