@keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}

@mixin checkboxBase {
  .checkbox {
    background: #707070;
    visibility: hidden;

    &:checked {
      ~ .checkbox-label {
        background: #707070;
        border-color: transparent;
        animation: jelly 0.3s ease;

        &:after {
          opacity: 1;
          transform: rotate(45deg) scale(1);
        }
      }
    }
  }

  .checkbox-label {
    cursor: pointer;
    border: 1px solid #c8ccd4;
    border-radius: 3px;
    height: 12px;
    margin-right: 2px;
    position: relative;
    top: 0;
    transition: 0.1s ease;
    vertical-align: middle;
    width: 12px;

    &:after {
      content: '';
      position: absolute;
      top: 0.5px;
      left: 3.5px;
      width: 3.5px;
      height: 8px;
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg) scale(0);
      opacity: 0;
      transition: all 0.3s ease;
      transition-delay: 0.15s;
    }

  .checkbox-text-label {
    color: #707070;
    cursor: pointer;
    margin-left: 0.5rem;
  }
}

  .checkbox-text-label {
    color: #707070;
    cursor: pointer;
    margin-left: 0.5rem;

    &.column-tooltip {
      border-bottom: 1px dotted #adadad;

      &:hover {
        cursor: help;
      }
    }
  }
}

.column-selector-container {
  background-color: #fff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.25);
  font-size: 0.85rem;
  margin-left: 0% !important;
  max-width: 75%;
  overflow: auto;
  padding: 15px 20px;
  position: absolute;
  top: 115px;
  width: 750px;
  z-index: 5;

  &.status {
    top: 113px;
  }

  .column-checkbox-container {
    @include checkboxBase;

    .column-checkbox {
      @extend .checkbox;
    }

    .column-checkbox-label {
      @extend .checkbox-label;
    }

    .column-checkbox-text-label {
      @extend .checkbox-text-label;
    }
  }
}

.column-selector-wrapper {
  > p {
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 15px;
  }

  ul {
    column-count: 3;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-bottom: 0.3rem;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    ul {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  }

  @media screen and (max-width: 576px) {
    ul {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }
  }
}

@media screen and (max-width: 576px) {
  .column-selector-wrapper {
    height: 400px;
    overflow: auto;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  .column-selector-container {
    max-width: 85%;
  }
}

.legal-memo-selector-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 5px 6px rgba(0,0,0,0.25);
  background-color: #F7F7F7;
  font-size: 0.85rem;
  padding: 8px 20px;
  top: 115px;
  width: 100%;
  z-index: 5;

  .legal-memo-checkbox-container {
    @include checkboxBase;

    .column-checkbox { @extend .checkbox; }
    .column-checkbox-label { @extend .checkbox-label; }
    .column-checkbox-text-label { @extend .checkbox-text-label; }
  }
}

.legal-memo-selector-wrapper {
  > p {
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 15px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-bottom: 0.3rem;
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .legal-memo-selector-wrapper {
    height: 400px;
    overflow: auto;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 992px) {
  .legal-memo-selector-container {
    max-width: 85%;
  }
}

.match-columns-btn {
  color: #4a5f96; 
  border: 1px solid #4a5f96;
  background-color: transparent;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #4a5f96;
    border-color: #4a5f96;
  }
}
