/* Classes used throughout PageHistory.js */
.return-button{
    background-color: #fff;
    border-radius: 4px;
    color:#484848;
    padding: 4px 15px;
    text-decoration: none;
    transition: .3s ease;
    &:hover{
        background-color: #7388c1;
        color: #fff;
        text-decoration: none;
    }
}

ins {
    background-color:#aaffaa !important;
    text-decoration: none !important;
    * {background-color:#aaffaa !important}
}
del {background-color:#ffaaaa !important;
    * {background-color:#ffaaaa !important}
}

/* Other styles */
.page-no-history-wrapper{
    align-items: center;
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
    justify-content: center;
    img{
        margin-bottom: 15px;
        width: 300px;
    }
    h1{
        font-size: 2.5rem;
    }
}
        
.archived-history-heading{
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 0px !important;
    padding-top: 20px;
    text-align: center;
    
}

.scroll-select{
    max-width: 100%;
    .archive {
        align-items: center;
        background-color: #f5f5f5;
        border-radius: 4px;
        box-shadow: -1px 2px 20px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        display: flex;
        font-size: .9rem;
        min-height: 50px;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 5px 10px;
        transition: .1s ease;
        &:hover{
            background-color: #7388c1;
            color: #fff;
        }
        .edited-by{
            font-size: .8rem;
            span{
                font-weight: 500;
            }
        }
        .current-version-text{
            font-size: .8rem;
            margin-left: 30px;
            text-transform: uppercase;
        }
        .revert-button{
            background-color: #7875b3;
            border: none;
            border-radius: 4px;
            color: #ffffff;
            outline: none;
            padding: 2px 10px;
            &:hover{
                box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.21);
            }
        }
    }
}

.article-text-edits-wrapper{
    background-color: #fff;
    border-radius: 4px;
    height: calc(100vh - 200px);
    overflow-y: auto;
    padding: 20px;
    width: 100%;
    .display-scroll-text{
        color: #737373;
        margin-top: 32vh;
        text-align: center;
    }            
    .item {
        background: gainsboro;
        border-radius: 5px;
        display: inline-block;
        margin: 2px;
        padding: 2px;
        width: fit-content;
        &.added {
            background-color:#aaffaa !important;
        }
        &.removed {
            background-color:#ffaaaa !important
        }
    }
}
