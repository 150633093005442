.create-ls-button {
  background-color: #293042;
  border-radius: 200px;
  border: 0px;
  bottom: 20px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  height: 75px;
  position: absolute;
  right: 20px;
  transition: all 0.2s ease;
  width: 75px;
  z-index: 10;
  .create-new-text {
    display: none;
  }
  &:hover {
    background-color: #3e5182;
    padding-right: 15px;
    width: auto;
    .create-new-text {
      display: inline;
    }
  }
  &:active {
    background-color: #324169;
  }
  &.open-sidebar {
    right: 410px;
    transition: all 0.3s ease;
  }
  &.hide {
    margin-bottom: -100px;
  }
}
.column-selector-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.25);
  font-size: 0.85rem;
  margin-left: 0% !important;
  max-width: 75%;
  overflow: auto;
  padding: 15px 20px;
  position: absolute;
  top: 115px;
  width: 750px;
  z-index: 5;
  &.status {
    top: 113px;
  }
}
.input-search-wrapper {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 50px;
  position: relative;
  justify-content: center;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  &.expanded {
    box-shadow: 0px 0px 0px 2000px #00000047;
    justify-content: flex-start;
    padding-top: 10px;
    transition: height 0.5s cubic-bezier(0.87, -0.41, 0.19, 1.44);
    z-index: 5;
  }
  .query-filter-search {
    align-items: center;
    background-color: #2a3142;
    box-shadow: 6px 0px 7px 0px rgba(0, 0, 0, 0.15);
    color: white;
    display: flex;
    font-size: 0.8rem;
    height: 100%;
    min-width: 160px;
    padding-left: 60px;
    padding-right: 10px;
    z-index: 1;
  }

  svg.search-icon {
    color: #4540a2;
    font-size: 22px;
    left: 20px;
    position: absolute;
    top: 13px;
    // &.filter {
    //   background-color: #2a3142;
    //   color: #fff;
    //   z-index: 2;
    // }
  }
  svg.search-popover-icon {
    color: #969696;
    cursor: pointer;
    &:active {
      color: #8a8a8a;
    }
    background-color: #fff;
    color: #afafaf;
    font-size: 22px;
    position: absolute;
    right: -19px;
    top: 0;
    &:hover {
      color: #96b3fb;
      cursor: pointer;
    }
    &.expanded {
      background-color: #afb1b8;
      color: #fff;
    }
  }
  .ls-loading-spinner {
    color: #3c9fe6;
    left: 16px;
    position: absolute;
    top: 10px;
    &.filter {
      color: #fff;
      z-index: 2;
    }
  }
  .input-search-box {
    border: none;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    height: 100%;
    margin-right: 20px;
    outline: none;
    padding-left: 60px;
    width: 100%;
    // &.filter {
    //   padding-left: 20px !important;
    // }
  }
  .adv-button {
    color: #4a5e96;
    min-width: 120px;
    &:hover,
    &:focus,
    &:active {
      color: #4a5e96 !important;
    }
    svg {
      margin-left: 15px;
    }
  }
  .vertical-line {
    height: 30px;
    width: 2px;
    background-color: #c4c4c4;
    margin-inline: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}
.search-menu-container {
  color: #a9a9a9;
  height: 78px;
  .search-menu-sub-container {
    max-width: 960px;
    .create-ls-button {
      background-color: #293042;
      border-radius: 200px;
      border: 0px;
      bottom: 20px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      color: #fff;
      height: 75px;
      position: absolute;
      right: 20px;
      transition: all 0.2s ease;
      width: 75px;
      z-index: 10;
      .create-new-text {
        display: none;
      }
      &:hover {
        background-color: #3e5182;
        padding-right: 15px;
        width: auto;
        .create-new-text {
          display: inline;
        }
      }
      &:active {
        background-color: #324169;
      }
      &.open-sidebar {
        right: 410px;
        transition: all 0.3s ease;
      }
      &.hide {
        margin-bottom: -100px;
      }
    }
    .input-search-wrapper {
      background-color: #fff;
      border-radius: 25px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
      height: 50px;
      .query-filter-search {
        background-color: #2a3142;
        box-shadow: 6px 0px 7px 0px rgba(0, 0, 0, 0.15);
        font-size: 0.8rem;
        min-width: 160px;
        padding-left: 60px;
        padding-right: 10px;
        z-index: 1;
      }
      svg {
        background-color: #fff;
        color: #afafaf;
        font-size: 22px;
        position: absolute;
        &:hover {
          color: #96b3fb;
          cursor: pointer;
        }
        &.search-icon {
          color: #4540a2;
          left: 20px;
          top: 13px;
          &.filter {
            background-color: #2a3142;
            color: #fff;
            z-index: 2;
          }
        }
        &.clear-search-icon {
          right: 10px;
          top: 13px;
        }
      }
      .ls-loading-spinner {
        color: #3c9fe6;
        left: 16px;
        position: absolute;
        top: 10px;
        &.filter {
          color: #fff;
          z-index: 2;
        }
      }
      .input-search-box {
        outline: none;
        padding-left: 60px;
        &.filter {
          padding-left: 20px !important;
        }
      }
    }
    .search-popover-icon {
      color: #969696;
      cursor: pointer;
      &:active {
        color: #8a8a8a;
      }
    }
  }
}
.search-feature-guide-modal-container {
  :global(.modal-content) {
    transform: translate(-20%, 7%);
    width: 865px;
  }
  .search-feature-guide-modal-body {
    font-size: 0.8rem !important;
  }
}
.dropdown-options-container {
  margin-bottom: 8px;
  .dropdown-options-bar {
    align-items: center;
    display: flex;
    height: 37px;
    justify-content: space-between;
    .dropdown-options-left {
      align-content: space-between;
      align-items: center;
      display: flex;
      float: left;
      margin-left: 1%;
      .day-toggle-buttons-container {
        margin-right: 20px;
        .day-toggle-buttons {
          align-items: center;
          background-color: #4a5f96;
          border: #566fb1;
          display: inline-flex;
          flex-direction: row;
          font-size: 0.7rem;
          height: 34px;
          line-height: 0.8rem;
          padding: 0 0.75rem;
          &:global(.focus) {
            background-color: #3b4c79;
            box-shadow: none;
          }
        }
        ul {
          margin: 0px;
          padding-left: 0px;
        }
      }
      .dropdown-left-buttons {
        cursor: pointer;
        border: 0;
        display: inline;
        margin-right: 5px;
        padding: 4px 10px;
        position: relative;
        white-space: nowrap;
        &:active,
        &:focus {
          background-color: #ccd2e2;
          border-radius: 4px;
          box-shadow: none;
          padding: 4px 10px;
        }
        span {
          font-size: 0.85rem;
          margin: 0 8px;
        }
        .counter {
          background: #5753ab;
          border-radius: 50%;
          border: 2px solid #f1f5ff;
          color: #fff;
          display: inline-block;
          font-size: 0.675rem;
          height: 20px;
          left: 22px;
          line-height: 1.1rem;
          margin-left: 0;
          padding: 1px;
          position: absolute;
          text-align: center;
          top: -3px;
          transition: top 0.1s ease-out;
          width: 20px;
        }
      }
      .results-count {
        font-size: 0.8rem;
        margin-left: 25px;
      }
    }
    .dropdown-options-right {
      color: #fff !important;
      cursor: pointer;
      float: right;
      margin-right: 1%;
      :global(.dropdown-toggle) {
        background-color: #4a5f96;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 600;
        padding: 4px 10px;
      }
      span {
        font-size: 0.85rem;
        margin-left: 5px;
      }
      :global(.dropdown-item) {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        a {
          color: inherit;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .export-dropdown-container {
        background-clip: padding-box;
        background-color: #fff;
        border-radius: 0.2rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
        color: #6c757d;
        font-size: 0.825rem;
        list-style: none;
        min-width: 10rem;
        position: absolute;
        text-align: left;
        z-index: 1000;
        .export-dropdown-menu-item {
          background-color: transparent;
          border: 0;
          clear: both;
          color: #495057;
          cursor: pointer;
          display: block;
          font-weight: 400;
          padding: 0.35rem 1.5rem;
          text-align: inherit;
          white-space: nowrap;
          width: 100%;
          &:active {
            background-color: #2a3142;
            color: white;
          }
        }
      }
    }
  }
}

.day-toggle-buttons-container {
  label {
    align-items: center;
    background-color: #4a5f96;
    border: #566fb1;
    display: inline-flex;
    flex-direction: row;
    font-size: 0.7rem;
    height: 34px;
    line-height: 0.8rem;
    padding: 0 0.75rem;
    text-wrap: nowrap;
  }
  :global(.btn-check:checked + .btn) {
    background-color: #293042 !important;
    box-shadow: none !important;
  }
  :global(.btn):hover {
    background-color: #435789 !important;
  }
}
.table-filter-icon-btn {
  font-size: 0.85rem !important;
  padding: 4px 10px !important;
  &:hover {
    background-color: #ccd2e2 !important;
  }
  &:active {
    background-color: #b1b6c4 !important;
    border-color: #b1b6c4 !important;
  }
  .counter {
    background: #5753ab !important;
  }
}
.results-count {
  font-size: 0.8rem;
}

.options-dropdown:global(.dropdown-toggle),
.lsr-action-btn {
  background-color: #4a5f96 !important;
  font-size: 0.8rem !important;
  &:hover {
    background-color: #394a75 !important;
  }
}
.badge-count {
  color: #002164 !important;
  background-color: #c0d1f3;
}

ul.lsr-options-dropdown-menu {
  li {
    cursor: pointer;
    font-size: 0.825rem !important;
    padding: 0.35rem 1.5rem;
    white-space: nowrap;
    width: 100%;
    label {
      cursor: pointer;
    }
    :global(.form-check-input:checked) {
      background-color: #3b4c79 !important;
      border-color: #3b4c79 !important;
    }
    a {
      color: #495057;
    }
    &:active {
      background-color: #2a3142;
      color: white !important;
    }
  }
}
// START For FilterMenu.js file
.filter-menu-container {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1);
  font-size: 0.85rem;
  line-height: 16px;
  margin-left: 0% !important;
  max-width: 100%;
  overflow-y: hidden;
  padding-top: 15px;
  position: absolute;
  text-transform: uppercase;
  top: 170px;
  width: 500px;
  z-index: 5;
  .column-checkbox-container {
    .column-checkbox {
      background: #707070;
      visibility: hidden;
      &:checked {
        ~ .column-checkbox-label {
          animation: jelly 0.3s ease;
          background: #707070;
          border-color: transparent;
          &:after {
            opacity: 1;
            transform: rotate(45deg) scale(1);
          }
        }
      }
    }
    .column-checkbox-label {
      border-radius: 3px;
      border: 1px solid #c8ccd4;
      cursor: pointer;
      height: 12px;
      margin-right: 2px;
      position: relative;
      top: 0px;
      transition: 0.1s ease;
      vertical-align: middle;
      width: 12px;
      &:after {
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        content: '';
        height: 8px;
        left: 3.5px;
        opacity: 0;
        position: absolute;
        top: 0.5px;
        transform: rotate(45deg) scale(0);
        transition-delay: 0.15s;
        transition: all 0.3s ease;
        width: 3.5px;
      }
    }
    .column-checkbox-text-label {
      color: #707070;
      cursor: pointer;
      margin-left: 0.5rem;
      &.column-tooltip {
        border-bottom: 1px dotted #adadad;
        &:hover {
          cursor: help;
        }
      }
    }
  }
  .filter-options-container {
    height: calc(100vh - 300px);
    max-height: 300px;
    overflow: auto;
    padding: 14px;
    .filter-option-row {
      background-color: #ececec;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .filter-option-hidden-check {
        opacity: 0;
        position: absolute;
      }
      .filter-option-label {
        color: #000000;
        float: left;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        text-transform: uppercase;
      }
      .filter-option-clear {
        color: #5577ce;
        cursor: pointer;
        float: right;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
      }
      .filter-option-dropdown {
        background: #fff;
        border-radius: 6px;
      }
    }
  }
}

.search-type-toggle {
  position: relative;
  display: inline-flex;
  border: 1px solid #4a5e96;
  border-radius: 2rem !important;
  overflow: hidden;
}

.search-type-btn {
  flex: 1;
  transition: color 0.3s ease;
  z-index: 1;
  color: #343a40;
  background-color: #fff;
  border: none;
  &.btn-active {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

.background-shift {
  position: absolute;
  height: 100%;
  border-radius: 2rem;
  background-color: #4a5e96;
}

.shift-right {
  transform: translateX(100%);
}
