.welcome-section {
  background: no-repeat;
  background-image: url('../../public/img/plant-background.jpg');
  background-size: cover;
  background-position: bottom;
  video {
    max-width: 720px;
  }
}
.video-card-container {
  transition: box-shadow 0.2s ease-in-out;
  background-color: #f1f5ff !important;
  h5 {
    color: #5753ab;
  }
  p {
    font-size: 0.9rem;
  }
  &:hover {
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.01),
      0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.02);
  }
}
