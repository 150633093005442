.user-profile-container {
  display: flex;
  flex-direction: row;
  height: calc(100% - 54px);

  &.my-profile {
    display: block !important;
  }

  .user-profile-main {
    background-color: #6192a5;
    border-radius: 8px;
    height: calc(100vh - 105px);
    height: 290px;
    min-width: 220px;
    overflow: auto;
    padding: 0px 2px;
    position: absolute;
    top: 120px;
    z-index: 3;

    .user-table {
      top: 32px;

      td {
        height: 40px;
        padding: 0px;
        text-align: left;
      }

      .user-row {
        .user-name-email {
          font-weight: 300;
          width: 80%;

          button {
            background-color: transparent;
            border: none;
            color: #000000;
            cursor: pointer;
            font-weight: 500;
            text-align: left;
            width: 100%;
          }
        }
      }

      tr:nth-child(even) {
        background: #6698ac;
      }
    }
  }
}

.outer-main-div-class {
  align-content: center;
  padding-left: 130px;
  width: 90%;
}

.diplay-info-bar-search-steps {
  display: flex;
  justify-content: space-between;
  padding-right: 60px;
  width: 95%;
}

.search-bar {
  font-size: 14px;
  height: 40px;
  margin: 5px;
  padding-left: 20px;
  text-align: left;
  width: 220px;
}

.info-display,
.calories-info {
  font-size: 22px;
  margin: 20px;
  padding-left: 5px;
}

.floor-plan-svg-main-container {
  align-items: center;
  border: 2px solid black;
  box-shadow: 0 14px 24px rgba(0, 0, 0, 0.55), 0 14px 18px rgba(0, 0, 0, 0.55);
  cursor: grab;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 2;
}

.floor-plan-svg-main-container-inner {
  left: 0px;
  top: 0px;
  transform: scale(1);
  transform-origin: center;
}

.desk-tooltip-info {
  font-size: 10px;
  height: 123px;
  text-align: center;
  white-space: normal;
  width: 115px;
}

.desk-profile-pic {
  border-radius: 50%;
  overflow: hidden;
}

.desk-profile-pic,
.desk-profile-pic-icon {
  height: 35px;
  max-height: 100px;
  width: 35px;
}

.broadway-st-img {
  right: 20px;
  width: 10%;
}

.floor-plan-svg-main-container,
.floor-plan-svg-main-container-inner,
.broadway-st-img {
  position: relative;
}

.GOV-Xerox-1 {
  right: 87%;
  top: 87.4%;
}

.GOV-PRT-2 {
  right: 50%;
  top: 86.4%;
}

.GOV-PRT-1 {
  right: 27%;
  top: 86.4%;
}

.INV_PRT_2 {
  right: 1.8%;
  top: 62.35%;
  transform: rotate(270deg);
}

.HUM-XEROX-1 {
  right: 22.7%;
  top: 45.4%;
}

.HUM-PRT-1 {
  right: 38.9%;
  top: 12%;
}

.HUM-PRT-2 {
  right: 49.7%;
  top: 12%;
}

.GOV-Xerox-1,
.GOV-PRT-2,
.GOV-PRT-1,
.INV_PRT_2,
.HUM-XEROX-1,
.HUM-PRT-1,
.HUM-PRT-2 {
  height: 1.9%;
  position: fixed;
  width: 2.02%;
}

.GOV-Xerox-1,
.GOV-PRT-2,
.GOV-PRT-1,
.HUM-XEROX-1 {
  transform: rotate(180deg);
}

.printer-tooltip-info {
  font-size: 10px;
  height: 60px;
  text-align: center;
  white-space: normal;
  width: 85px;
}

@media (min-width: 1919px) {
  .floor-plan-svg-main-container {
    height: 810px;
    left: 150px;
    width: 980px;
  }

  .floor-plan-svg-main-container-inner {
    height: 1100px;
    width: 980px;
  }
}

@media (max-width: 1919px) {
  .floor-plan-svg-main-container {
    height: 675px;
    left: 75px;
    width: 815px;
  }

  .floor-plan-svg-main-container-inner {
    height: 800px;
    width: 815px;
  }

  .broadway-st-img {
    left: 5%;
  }
}
