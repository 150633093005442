/* FONT IMPORTS */
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

@mixin reportTracking($fontColor) {
  color: $fontColor;
}

.report-filters {
  overflow: hidden;
  position: relative;
  input,
  span {
    padding: 5px;
  }
  // styles in renderHeader() function
  .calendarHeader {
    align-items: center;
    color: #566fb1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 500;
    font-size: 1.2rem;
    height: 65px;
    justify-content: space-between;
    margin: 0 !important;
    min-width: 580px;
    padding: 0px 15px 0px 15px;
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 2;
    .current-month-container {
      border-radius: 4px;
      cursor: pointer;
      display: inline-block;
      padding-inline: 5px;
      &:hover,
      &:focus {
        background-color: #f2f5fe;
        box-shadow: none;
      }
      &:active {
        background-color: #eaecf7;
      }
      .current-month-wrapper {
        font-size: 30px;
        padding: 0px !important;
      }
      .current-year-wrapper {
        font-size: 28px;
        font-weight: 200;
        padding: 0px !important;
      }
    }
    .arrow-month-selection {
      border-radius: 100px;
      color: #586eb2;
      cursor: pointer;
      font-size: 0.9rem;
      height: 42px;
      padding: 0;
      width: 42px;
      &:hover,
      &:focus {
        background-color: #f2f5fe;
        box-shadow: none;
      }
      &:active {
        background-color: #eaecf7;
      }
    }
    .change-monthYear-container {
      width: 343px;
      .select-box {
        background-color: white;
        border: 1px solid #002164;
        color: #002164;
        cursor: pointer;
        font-size: 0.9rem !important;
        margin-left: 1%;
        margin-right: 1%;
        padding-left: 5px;
        text-align: left;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
          -webkit-box-shadow 0.15s ease-in-out;
        &:focus,
        &:hover {
          background-color: #586eb2;
          border-color: #586eb2;
          box-shadow: none;
          color: #fff;
          outline: none !important;
        }
      }
      .submit {
        background-color: #586eb2;
        border: 1px solid #fff;
        border-radius: 2rem;
        box-shadow: none;
        color: white;
        font-size: 1rem;
        margin-left: 3%;
        margin-right: 3%;
        padding: 7px;
        &:active {
          background-color: #324471;
        }
        &:hover {
          background-color: #455a8f;
        }
      }
      .close-monthYear-input-fields {
        background-color: #fff;
        box-shadow: none;
        color: #002164;
        cursor: pointer;
        line-height: 1rem;
        margin-left: 0;
        padding: 0px;
        &:active {
          color: #001744;
        }
      }
    }
    .calendar-view-toggle {
      color: #566fb1;
      border-color: #566fb1 !important;
      margin-right: 15px;
      &:focus,
      &:hover {
        background-color: #5770b1 !important;
        border-color: #5770b1 !important;
        color: #fff;
      }
    }
    .create-report-button {
      background-color: #586eb2;
      color: #fff;
      &.non-leg-staff {
        visibility: hidden;
      }
      &:hover {
        background-color: #002164;
      }
    }
    .dropdown-filter-toggle {
      color: #586eb2;
      font-size: 0.9rem;
      &:hover,
      &:focus {
        background-color: #f2f5fe;
        box-shadow: none;
      }
      &:active {
        background-color: #eaecf7;
      }
    }
    button {
      &:last-child,
      &:nth-last-child(2) {
        margin-left: 15px;
      }
    }
  }
  @media (max-width: 1070px) {
    .dropdown-filter-toggle {
      border-radius: 100px;
      height: 42px;
      padding: 0;
      width: 42px;

      > svg {
        padding-right: 0px !important;
      }
    }
  }
  // styles in renderDays() function
  .daysRows {
    align-content: center;
    background-color: #586eb2;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    font-size: 80%;
    height: 24px;
    width: 100%;
    position: sticky;
    top: 65px;
    z-index: 1;
    .daysCol {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      min-height: 1px;
      margin: 0;
      position: relative;
      width: 100%;
      padding-right: 12px;
      text-align: center;
      z-index: 1;
    }
  }
  .row-container {
    border-top: 1px solid #dce2e9;
    display: grid;
    height: calc(100vh - 143px);
    grid-auto-rows: 1fr;
    overflow: auto;
    :last-child {
      border-bottom: 0px !important;
    }
    // styles in renderCells() function
    .temp-row {
      border-bottom: 1px solid #eee;
      display: flex;
      flex-wrap: wrap;
      margin: 0 !important;
      padding: 0;
      width: 100%;
      .colCell,
      .colCellSelected,
      .colCellDisabled {
        background: #fff;
        border-right: 1px solid #eee;
        display: inline-block;
        flex-basis: 0;
        flex-grow: 1;
        margin: 0;
        max-width: 100%;
        min-height: 6.8em;
        overflow: hidden;
        position: relative;
        transition: 0.25s ease-out;
        width: 100%;
        .cellNumber {
          font-size: 65%;
        }
        .bg {
          color: #1a8fff;
          font-size: 8em;
          font-weight: 700;
          letter-spacing: -0.07em;
          line-height: 1;
          opacity: 0;
          position: absolute;
          right: -0.05em;
          top: -0.2em;
          transition: 0.25s ease-out;
        }
        .report-calendar-day-container {
          margin-bottom: 1em;
          margin-top: -18px;
          text-align: center;
          .report-tracking-upcoming,
          .report-tracking-past-due,
          .report-tracking-completed,
          .report-tracking-received {
            font-size: 0.7rem;
            margin: 0px 3px;
          }
          .report-tracking-upcoming {
            @include reportTracking(#af9308);
          }
          .report-tracking-past-due {
            @include reportTracking(#d05d4e);
          }
          .report-tracking-completed {
            @include reportTracking(#495057);
          }
          .report-tracking-received {
            @include reportTracking(#1e7e34);
          }
          .completed{
            background-color: #495057;
          }
          .past_due{
            background-color: #d05d4e;
          }
          .received{
            background-color: #1e7e34;
          }
          .upcoming{
            background-color: #AF9308;
          }
          .report-title-display{
            font-size: 0.8rem;
            color: white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0.2rem;
            padding: 0rem 0.2rem;
          }
          p {
            font-size: 0.8rem;
            margin: 0px;
          }
          .total-reports-num {
            font-size: 1.2rem;
            margin-bottom: 0px;
            margin-top: 1em;
            .tracker {
              font-size: 1rem;
            }
          }
        }
      }
      .colCell:hover {
        background: #f9f9f9;
        transition: 0.5s ease-out;
      }
      .colCellSelected {
        border-left: 1px solid #1a8fff;
        border-right: 1px solid #1a8fff;
        box-shadow: 0 0 5px #719ece;
      }
      .colCell:hover,
      .colCellSelected {
        > .bg {
          //reveals large date number in calendar cell
          opacity: 0.05;
          transition: 0.5s ease-in;
        }
      }
      .colCellDisabled {
        color: #ccc;
        pointer-events: none;
      }
    }
  }
}

.popover-committee-outer-container {
  > div {
    border: none !important;
    width: 300px !important;
  }
  .popover-committee {
    width: 300px !important;
    border-radius: 6px !important;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.29);
  }
}

.popover-outer-container {
  > div {
    border: none !important;
  }
  .popover-container {
    border-radius: 6px !important;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 205px !important;
    .filter-dropdown-wrapper {
      &:global(.popover-body) {
        padding: 8px;
      }
      .color-filter-container {
        cursor: pointer;
        display: block;
        font-size: 0.9rem;
        position: relative;
        padding: 7px 0px 2px 54px;
        user-select: none;
        &.past-due {
          color: #d05d4e;
        }
        &.received {
          color: #1e7e34;
        }
        &.completed {
          color: #495057;
        }
        &.upcoming {
          color: #af9308;
        }
        &.past-due:hover input ~ .color-filter-checkmark,
        &.past-due input:checked ~ .color-filter-checkmark.past-due {
          background-color: #d05d4e;
        }
        &.received:hover input ~ .color-filter-checkmark,
        &.received input:checked ~ .color-filter-checkmark.received {
          background-color: #1e7e34;
        }
        &.completed:hover input ~ .color-filter-checkmark,
        &.completed input:checked ~ .color-filter-checkmark.completed {
          background-color: #495057;
        }
        &.upcoming:hover input ~ .color-filter-checkmark,
        &.upcoming input:checked ~ .color-filter-checkmark.upcoming {
          background-color: #af9308;
        }
        input {
          cursor: pointer;
          height: 0;
          position: absolute;
          opacity: 0;
          width: 0;
          &:checked ~ .color-filter-checkmark:after {
            display: block;
          }
        }
        .color-filter-checkmark {
          border-radius: 5px;
          height: 20px;
          left: 18px;
          position: absolute;
          top: 9px;
          width: 20px;
          &.past-due {
            border: 2px solid #d05d4e;
          }
          &.received {
            border: 2px solid #1e7e34;
          }
          &.completed {
            border: 2px solid #495057;
          }
          &.upcoming {
            border: 2px solid #af9308;
          }
          &:after {
            border: solid white;
            border-width: 0 3px 3px 0;
            content: '';
            display: none;
            height: 10px;
            left: 6px;
            position: absolute;
            top: 3px;
            transform: rotate(45deg);
            width: 5px;
          }
        }
        .filter-popover-user-guide {
          color: #838383;
          position: absolute;
          right: 8px;
          top: 10px;
        }
      }
      .status-icons {
        padding-right: 10px;
      }
    }
  }
}
