

.page{
    composes: page from "./Wiki.module.scss";
    height: 100%;
    max-width: 1140px;
    // Classes below are shared with SubjectPage.js and TagPage.js
    .title{ // Still working on the style for this
        font-size: 3.5rem;
        line-height: 1em;
        margin-bottom: 0px;
        margin-right: 0px;
        .edit-subject-page{
            color: #000;
            font-size: 1.2rem;
            margin-left: 10px;
        }
    }

    // Classes below are shared on SubjectPage.js and WikiSearch.js
    .results-count{
        background-color: #5942b3;
        border-radius: 25px;
        color: #fff;
        display: inline-block;
        font-size: 1rem;
        font-weight: 600;
        height: 30px;
        line-height: 1.4rem;
        padding: 5px;
        text-align: center;
        vertical-align: middle;
        width: 30px;
    }
    .card-body-gray{
        background-color: #3d4761;
        border: 0px !important;
        border-color: #3d4761;
        border-radius: 8px;
        color: #fff;
        padding: 20px !important;
    }

    .topic-list-item{
        background-color: #3d4761;
        color: #fff;
        &:hover{
            color:#fff;
        }
        &:active{
            background-color: #262d3e;
            color: #fff;
        }
    }

    // Classes below are exclusive to WikiSearch.js
    .search-bar-and-title-wrapper{
        color: #3d4761;
        padding: 20px 0;
        text-align: center;
        h2{
            font-weight: 200;
        }
    }
    .no-valid-result-container{
        align-items: center;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 164px);
        justify-content: center;
        p{
          font-size: 18px;
          margin-bottom: 0;
          text-align: center;
        }
        img{
          margin-bottom: 1rem;
          max-width: 430px;
        }
      }
}

@media only screen and (max-width: 1200px){
    .page {
        .title{ font-size: 2.3rem; }
    }
}
