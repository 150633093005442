.widget-container{
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    border-radius: 3px;
    height: 100%;
    overflow: hidden;
    .table-header-container{
        align-items: center;
        display: flex;
        height: 50px;
        justify-content: space-between;
        padding: 0px 22px;
        h3{
            font-size: 1rem !important;
            margin-bottom: 0px;
        }
        a {
            background-color: transparent;
            border: none;
            color: #3f4246;
            cursor: pointer;
            font-size: .9rem;
            font-weight: 600;
            letter-spacing: 0.03rem;
            padding: 4px 0px 4px 8px;
            text-decoration: none;
            transition-duration: 0.2s;
            &:hover{
                background-color: #4540a2;
                border-radius: 4px;
                color: #fff;
            }
        }
    }
    .clex-dashboard-table{
        thead{
            display: table;
            table-layout: fixed;
            width: 100%;
            tr{
                th.clex-dashboard-table-headers{
                    background-color: #3b3a54;
                    border-bottom-width: 0px !important;
                    border-right: 1px solid white;
                    color: white;
                    font-size: .7rem;
                    font-weight: bold;
                    line-height: 14px;
                    padding: 10px 18px !important;
                    position: sticky;
                    text-align: center;
                    top: 0px;
                    width: 90px;
                    z-index: 1;
                    &:last-child{
                        border-right: none;
                    }
                }
            }
        }
        tbody{
            border-top: none !important;
            cursor: pointer;
            display: block;
            max-height: 360px;
            overflow-y: auto;
            tr{
                display: table;
                margin-bottom: .5rem;
                table-layout: fixed;
                width: 100%;
                &:nth-of-type(odd){
                    background-color:#FFF;    
                }
                &:nth-of-type(even){
                    background-color:#f2f2f2;
                }
                td{
                    border: none;
                    border-bottom: 1px solid #ccc;
                    border-top: 1px solid rgba(189, 189, 189, 0.38);
                    height: 60px;
                    text-align: center;
                    width: 90px;
                }
                td, td pre{
                    color:black;
                    font-size: 11px;
                    line-height: 15px;
                }
            }
        }
    }
}