@keyframes jelly {
    from {transform: scale(1, 1);}
    30% {transform: scale(1.25, 0.75);}
    40% {transform: scale(0.75, 1.25);}
    50% {transform: scale(1.15, 0.85);}
    65% {transform: scale(0.95, 1.05);}
    75% {transform: scale(1.05, 0.95);}
    to {transform: scale(1, 1);}
}

.checkbox-selector-container{
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.25);
    font-size: .85rem;
    margin-right: 3% !important;
    max-width: 75vw;
    overflow: auto;
    padding: 15px 20px;
    position: absolute;
    right: 0;
    top: auto;
    width: 750px;
    z-index: 3;
}
.checkbox-selector-container-div{
    background-color: transparent;
    border-radius: 8px;
    font-size: .85rem;
    margin-right: 3% !important;
    overflow: auto;
    padding-top: 8px;
}
.checkbox-container{
    white-space: nowrap;
    .value-checkbox{
        background: #707070;
        visibility: hidden;
        &:checked {
            ~ .checkbox-label {
                background: #707070;
                border-color: transparent;
                animation: jelly 0.3s ease;
                &:after {
                    opacity: 1;
                    transform: rotate(45deg) scale(1);
                }
            }
        }
    }
    .checkbox-label{
        cursor: pointer;
        border: 1px solid #c8ccd4;
        border-radius: 3px;
        height: 12px;
        margin-right: 2px;
        position: relative;
        top: 0px;
        transition: 0.1s ease;
        vertical-align: middle;
        width: 12px;
        &:after{
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
            content: '';
            height: 8px;
            left: 3.5px;
            opacity: 0;
            position: absolute;
            top: .5px;
            transform: rotate(45deg) scale(0);
            transition: all 0.3s ease;
            transition-delay: 0.15s;
            width: 3.5px;
        }
    }
    .checkbox-text-label{
        color: #707070;
        cursor: pointer;
        margin-left: .5rem;
        &.checkbox-tooltip{
            border-bottom: 1px dotted #adadad;
            &:hover{ cursor: help; }
        }
    }
    
}
.column-selector-wrapper{
    ul{
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li{
        display: inline-block;
        margin-bottom: .3rem;
        width: 100%;
    }
    @media screen and (max-width:768px){
        ul{
            -moz-column-count: 2;
            -webkit-column-count: 2;
            column-count: 2;
        }
    }
    @media screen and (max-width:576px){
        ul{
            -moz-column-count: 1;
            -webkit-column-count: 1;
            column-count: 1;
        }
    }
    
    &.column-count-2{
        ul{
            -moz-column-count: 2;
            -webkit-column-count: 2;
            column-count: 2;
        }
        @media screen and (max-width:1270px){
            ul{
                -moz-column-count: 1;
                -webkit-column-count: 1;
                column-count: 1;
            }
        }
    }
    
    &.column-count-1{
        ul{
            -moz-column-count: 1;
            -webkit-column-count: 1;
            column-count: 1;
        }
    }
}
@media screen and (max-width:576px){
    .column-selector-wrapper1{
        height: 400px;
        overflow: auto;
        margin: 0;
        padding: 0;
    }
}
.popover-container {
    box-shadow: 0px 20px 40px 0px rgba(0,0,0,0.2);
    border-radius: 6px !important;
    padding: 6px;
    div{
        font-size:.7rem;
    }
}
  
.popover-outer-container{
    :global(.arrow::before){
        border-width: 0px !important;
    }
    > div {
        border: none !important;
    }
}

@media screen and (max-width: 992px){
    .checkbox-selector-container{
        max-width: 85%;
    }
}