// ---------- Classes for multiple components ---------- //
.div-row {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  padding: 5px;
  .sectionArray {
    margin-bottom: 20px;
  }
}

// ---------- components/wiki/PageForm.js ---------- //
.page{
  composes: page from "./Wiki.module.scss";
  margin-left: 0px;
  max-width: 100%;
  padding-right: 0px;
}
.edit-article-title-container{
  background-color: #5850EC;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 20px 10px;
  text-align: center;
}
.create-article-container{
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5934b8), to(#5850ec));
  background-image: linear-gradient(180deg, #5934b8, #5850ec);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
  display: flex;
  font-size: 2.5rem;
  font-weight: 200;
  justify-content: center;
  max-width: 960px;
  min-height: 100px;
  position: sticky;
  top: 51px;
  z-index: 1;
}
.return-to-article{
  background-color: transparent;
  border: none;
  color: #3d4761;
  font-size: .9rem;
  border-radius: 4px;
  padding: 4px 10px;
  outline: none;
  transition: .3s ease;
  &:hover{
    color: #fff;
    background-color: #606060;
  }
}
.form-heading-container{
  background-color: #f1f5ff;
}
.form-wrapper{
  padding: 0px 20px;
  max-width: 1100px;
  &.mytasks{
    background-color: transparent;
    border-radius: 0px;
    height: 100%;
    overflow-y: auto;
    padding: 0px 20px 0px 0px;
  }
  .inputMargin {
    margin-right: 10px !important;
  }
  .edit-form-select-container{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #e5e9f3;
  }
}
.article-footer-container{
  background-color: #f1f5ff;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
  position: sticky;
  max-width: 1100px;
  z-index: 1;
  &.mytasks{
    background-color: #f8fafc;
    bottom: -1px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
    padding: 10px 0px 0px;
    max-width: 100%;
  }
  .preview-button{
    background-color: transparent !important;
    border: transparent !important;
    color: #444444 !important;
    &:active{
      color: #1d1d1d !important;
    }
    &:focus{
      box-shadow: none !important;
    }
  }
}
.preview-modal-container{
  :global(.modal-content){
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.29) 0px 0px 18px 0px;
    height: 90vh;
    inset: 55% auto auto 50%;
    margin-right: -50%;
    outline: none;
    overflow: auto;
    position: relative;
    transform: translate(-50%, 0%);
    width: 90vw;
  }
  .preview-modal-header-container{
    align-items: center;
    background-color: #5850ec;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 5px 16px;
    position: sticky;
    top: 0;
    z-index: 1;
    .preview-modal-header-wrapper{
      font-size: 1.3rem;
    }
    .close-button{
      cursor: pointer;
      font-size: 2rem;
      &:active{
        color: #e8e8e8;
      }
    }
  }
  .preview-modal-body-container{
    padding: 0px 1rem 1rem 1rem !important;
    h1{
      background-color: #fff;
      color:#6764a9;
      font-size: 2rem;
      padding: 10px !important;
      position: sticky;
      top: 58px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1110px ){
  .button-footer-container{
    padding-right: 218px;
  }
}
// ---------- components/wiki/SectionFormBar.js ---------- //
.form-border {
  border: 2px solid #6764a9;
  border-radius: 6px;
  margin: 15px;
  overflow: hidden;
  .sectionBar {
    background: #6764a9;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    padding: 8px 15px;
    > span{
      width: 60%;
    }
    .right {
      margin-left: auto;
      .sectionBarButton {
        background-color:#fff !important;
        border: 1px 0px 0px 0px solid black !important;
        color: #6764a9 !important;
        margin: 0px 5px;
      }
    }
  }
  .collapse-border {
    padding: 5px 5px 0px 5px;
    .quillInput{
      //To get the child class which is a global
      > :global(.ql-container){
        background-color: #fff;
        height: 180px;
      }
    }
    .page-indent-1 {
      margin-left: 20px;
      margin-right: 5px;
    }
  }
}