.update-ls-request-header-container {
  color: #293042;
  > button {
    background-color: #f3f3f3;
    &:hover,
    &:focus {
      background-color: #dfdfdf;
    }
    &:active {
      background-color: #c4c4c4;
    }
  }
}
.error-message {
  align-items: center;
  background-color: #ca4a4a !important;
  border-color: #ca4a4a !important;
  border-radius: 0px !important;
  color: white !important;
  display: flex;
  height: 58px;
  justify-content: center;
  margin-bottom: 0px !important;
  padding: 10px !important;
  div {
    height: fit-content;
  }
  > div:first-child {
    font-size: 28px;
  }
  > div:nth-child(2) {
    font-size: 0.75rem;
    padding-left: 20px;
  }
}
.update-ls-request-body {
  font-size: 0.8rem;
  .input-form-title {
    font-weight: 500;
  }
  .ls-title {
    display: block;
  }
  .input-form-title,
  .ls-title {
    .not-visible-to-cm {
      font-size: 0.7rem;
      font-weight: 300;
    }
  }
  .warning-message {
    color: rgb(221, 68, 47);
    font-weight: 400;
  }
  .checkbox-display {
    padding-bottom: 5px;
    .checkbox {
      border: 1px solid #c8ccd4;
      border-radius: 3px;
      cursor: pointer;
      height: 17px;
      margin: 0px 5px 0px 0px !important;
      position: relative !important;
      transition: 0.1s ease;
      -webkit-transition: 0.1s ease;
      width: 17px;
    }
  }
  &.success {
    padding: 0 15px 15px;
    position: relative;
  }
}
.update-ls-request-footer {
  align-items: center;
  border-top: 1px solid #e1e1e1;
  display: flex;
  height: 55px;
  justify-content: flex-end;
  padding: 0 15px;
  z-index: 1;
}
.success-message-container {
  padding: 10px 0 0;
  background-color: #ffffff;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  .success-create-message {
    align-items: center;
    background-color: #76b04d !important;
    border-color: #76b04d !important;
    color: white !important;
    display: flex;
    height: 58px;
    justify-content: center;
    margin-bottom: 0 !important;
    p {
      margin-bottom: 0;
    }
  }
}
