.UserSelect {
  background-color: white;
  border-top: none;
  border: 1px solid lightgrey;
  cursor: pointer;
  list-style: none;
  max-height: 200px;
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 2;
  li {
    padding: 0 1em 0 1em;
  }
  li:hover {
    background-color: #719ece;
    color: white;
  }
}
