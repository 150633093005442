.side{
    align-items: center;
    background-color: #3d4761;;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    overflow: auto;
    padding: 20px;
    position: fixed;
    width: 250px;
    .clex-logo-wrapper{
        margin-bottom: 25px;
        text-align: center;
    }

    .create-new{
        background-color: #5750ec;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        font-size: .9rem;
        margin-bottom: 25px;
        padding: 5px 13px;
        text-decoration: none;
        transition: 0.1s ease-in;
        &:hover{
            background-color: #433dd0;
            border-radius: 5px;
            padding: 5px 13px;
            text-decoration: none;
        }
        svg{
            margin-right: 0.3rem;
        }
    }

    .model-crud-container{
        align-items: center;
        display: flex;
        a{color: #fff;:hover{color: #dedede;}}
        .model-crud-buttons{
            align-items: flex-end;
            border-bottom: 3px solid #fff;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            margin-right: 10px;
        }
        .button-options{
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            min-width: 90px;
            .link-subject{
                cursor: pointer;
                text-decoration: none;
            }
        }
    }
    .scroll-subjects {
        border-radius: 10px;
        box-shadow: inset 0px -20px 28px 0px rgba(0,0,0,0.2);
        counter-reset: item;
        display: flex;
        flex-direction: column;
        height: calc(100% - 40px);
        margin-top: 10px;
        min-width: 200px;
        overflow: auto;
        padding: 0px 15px 15px 15px;
        width: 100%;
        &.scroll-sub-full {height: calc(100vh - 366px) !important;}
        a{
            display: block;
            color: #fff;
            display: block;
            font-size: .9rem;
            font-weight: 300;
            padding: 8px;
            text-decoration: none;
            &:not(:last-of-type){border-bottom: 1px solid rgb(201, 201, 201);}
        }
    }
}
    
    