.comments{
    padding-bottom: 20px;
}
.comment{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    margin-right: 20px;
    .comment-content{
        .comment-user{
            
        }
    }
    .comment-timestamp{
        color: grey;
    }
}
.commentBox{
    width: 90%;
    margin-right: 20px;
}
h2 {
    margin-right: 10px;
}

.comments-button{
    background-color: #5750ec !important;
    font-size: 0.9rem !important;
    color: #fff;
    padding: 4px 8px !important;
    border-radius: 3px;
    border: 0px !important;
    height: 35px;
}
.commentRow {
    display: flex;
    flex-direction: row;
    border: none;
}