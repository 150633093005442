.inputMargin {
  margin-right: 10px !important;
}

.div-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.edit-page-field-title{
  font-size: 1.2rem;
  float: left;
  line-height: 2.1rem;
  margin-right: 10px;
}
.number-input {
  max-width: 80px;
}

.page{
  composes: page from "./Wiki.module.scss";
  margin-left: 0px;
  max-width: 100%;
  padding-right: 0px;
}

.form-wrapper{
  padding: 0px 20px 20px;
}
.drag {
  cursor: move;
}

.item-tab {
  background: #cccccc;
  padding: 10px;
  margin-bottom: 10px;
}

.row-button {
  margin: 0px 5px 5px 0px;
}
